import React from 'react'

import { withRouter, useLocation } from 'react-router-dom'
import { Hub } from 'aws-amplify'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

//import SortFacilityMenu from '../SortFacilityMenu.js'
import CardShifts from '../features/CardShifts/CardShifts.js'
import Utils from '../helpers/utils'

import withStyles from '@mui/styles/withStyles'

import { setUser, resetUser } from '../features/User/userSlice.js'
import { clearShiftCollections } from '../features/ShiftCollection/shiftCollectionSlice.js'
import UserService from '../services/userService.js'
import ShiftService from '../services/shiftService.js'

import authService from '../services/authService.js'
import { compose } from 'redux' //Code
import { connect } from 'react-redux' //Code

import Alert from '@mui/material/Alert'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import CircularProgress from '@mui/material/CircularProgress'

const useStyles = (theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
})

const mapStateToProps = (state) => ({
    user: state.user,
    shiftCollections: state.shiftCollections,
})
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (userState) => {
            dispatch(setUser(userState))
        },
        resetUser: () => {
            dispatch(resetUser())
        },
        clearShiftCollections: () => {
            dispatch(clearShiftCollections())
        },
    }
}

const Home = (props) => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    let [isLoggedIn, setIsLoggedIn] = React.useState(false)
    let [hasShiftsLoaded, setHasShiftsLoaded] = React.useState(false)

    const { hash } = useLocation()

    React.useEffect(() => {
        let refreshDataInterval = null
        console.log('laoding home page')

        Hub.listen('auth', async ({ payload }) => {
            const { event } = payload
            if (event === 'signIn' || event === 'autoSignIn') {
                console.log('auth event: user signed in')
                setIsLoggedIn(true)
                let userObj = await UserService.getAccount()
                console.log('reloading')

                Utils.loadChatUser(userObj)
                await ShiftService.getAvailableShifts()
                setHasShiftsLoaded(true)
                // set interval run
                refreshDataInterval = setInterval(() => {
                    console.log('running data refresh ' + new Date())
                    if (!userObj.isActivated || !userObj.isEnabled) {
                        UserService.getAccount()
                    }
                    ShiftService.getAvailableShifts()
                    setHasShiftsLoaded(true)
                }, 60000)

                // get query string
                // REDIRECT NEW USER TO ONBOARDING

                // if url hash = #onboardSuccess then user has been reouted form jotform so has signed nurselab staffing agreeemnt, race condition where jotform may be submitted but not saved to db yet
                if (
                    hash !== '#onboardingSuccess' &&
                    !UserService.hasNurseLabStaffingRegistrationAgreement(
                        userObj
                    )
                ) {
                    console.log('moving user to onboarding page')
                    props.history.push('/onboarding')
                    return
                }

                // only show widget if fully onboarded
                Utils.loadChatUser(userObj)
                //await ShiftService.getAvailableShifts()
                if (isDesktop) {
                    Utils.showTawk()
                }
            } else if (event === 'signOut') {
                setIsLoggedIn(false)
                console.log('signed out')
                clearInterval(refreshDataInterval)
                props.resetUser()
                props.clearShiftCollections()
                props.history.push('/')

                Utils.hideTawk()
            }
        })

        // load tawk when navigating to this page; because hidden when entering page from onboarding page where tawk is hidden
        if (isDesktop) {
            let firstRun = setInterval(function () {
                Utils.showTawk()
                clearInterval(firstRun)
            }, 300)
        }
    }, [])

    React.useEffect(() => {
        console.log('page init')
        if (props.shiftCollections.length > 0) {
            setHasShiftsLoaded(true)
        }
        //alert('page load')
        init()
    }, [])

    React.useEffect(() => {
        console.log('shift collection change ')
        if (props.shiftCollections.length > 0) {
            setHasShiftsLoaded(true)
        }
        //alert('page load')
    }, [props.shiftCollections])
    const init = async () => {
        console.log('pageload')
        //if (await authService.isLoggedInAndUserLoaded()) {
        if (await authService.isLoggedIn()) {
            setIsLoggedIn(true)
            console.log('IS LOGGED IN')
            let userObj = await UserService.getAccount()
            console.log('loaded user data')

            //await ShiftService.getAvailableShifts()
            // if url hash = #onboardSuccess then user has been reouted form jotform so has signed nurselab staffing agreeemnt, race condition where jotform may be submitted but not saved to db yet
            if (
                hash !== '#onboardingSuccess' &&
                !UserService.hasNurseLabStaffingRegistrationAgreement(userObj)
            ) {
                props.history.push('/onboarding')
            } else {
                ShiftService.getAvailableShifts()
                setHasShiftsLoaded(true)
            }
        } else {
            console.log('NOT LOGGED IN')
            setIsLoggedIn(false)
        }
    }

    //const { classes } = props

    // br spacing required for proper formatting on mobile
    let accountActivationAlert = (
        <div>
            <br />
            <Alert severity="success">
                Hi! We need to verify your application before you can request
                shifts. This process normally takes 24 - 48 hours. We will email
                you when your account has been activated. Please contact
                support@nurselab.co with any questions.
            </Alert>
        </div>
    )
    let accountDisabledAlert = (
        <div>
            <br />
            <Alert severity="success">
                Hi! Sorry, your account is disabled. Please contact customer
                support for further assistance at support@nurselab.co.
            </Alert>
        </div>
    )

    if (!hasShiftsLoaded) {
        return (
            <Container
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '70vh',
                    alignItems: 'center',
                }}
            >
                <CircularProgress size="8em" />
            </Container>
        )
    }

    // can't use container for this because grid container overrides main container and doesn't format on mobile properly
    return (
        <Container
            style={{
                maxWidth: '100%',
                width: '100%',
                marginLeft: isDesktop ? '3em' : '0',
                marginRight: isDesktop ? '3em' : '0',
                marginTop: isDesktop ? '2em' : '0',
                height: !isDesktop
                    ? `calc(100vh - 3.5em - ${props.bottomSafeArea}px - ${props.topSafeArea}px )`
                    : 'auto',
                overflowY: !isDesktop ? 'scroll' : 'auto',
            }}
        >
            {/*require first name to make sure user is loaded before showing account not activated/disabled errors*/}
            {isLoggedIn && props.user.firstName && !props.user.isActivated
                ? accountActivationAlert
                : null}
            {isLoggedIn && props.user.firstName && !props.user.isEnabled
                ? accountDisabledAlert
                : null}
            <br />
            <Grid container spacing={isDesktop ? 3 : 2}>
                <CardShifts />
            </Grid>
            <br />
            <br />
            <br />
        </Container>
    )
}

//export default withStyles(useStyles) connect(mapStateToProps, mapDispatchToProps)(withRouter(Home))
export default compose(
    withStyles(useStyles),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Home)
