const moment = require('moment')
const TzMoment = require('../plugins/TzMoment')

const utils = {
    showTawk: () => {
        if (window.Tawk_API && window.Tawk_API.showWidget) {
            window.Tawk_API.showWidget()
        }
    },
    hideTawk: () => {
        if (window.Tawk_API && window.Tawk_API.hideWidget) {
            window.Tawk_API.hideWidget()
        }
    },
    loadChatUser: (user) => {
        if (!window.Tawk_API) {
            return
        }

        if (!user.firstName || !user.email) {
            return
        }
        let name = user.firstName + ' ' + user.lastName
        let email = user.email

        let obj = {
            name: name,
            email: email,
        }
        if (window.Tawk_API.onLoaded) {
            window.Tawk_API.setAttributes(obj)
        } else {
            window.Tawk_API.onLoad = function () {
                window.Tawk_API.setAttributes(obj)
            }
        }
    },
    getIsFullyBooked: (bookedRequestsCount, quantity) => {
        if (bookedRequestsCount === quantity) {
            return true
        } else {
            return false
        }
    },

    // only used in payments.js candidate for removal if payments page is dep
    prettyFormatDateTimeRange: (shift) => {
        let shiftRange = shift.dateTimeRange
        let start = shiftRange[0]
        let end = shiftRange[1]
        let tzMoment = new TzMoment(shift.timezone)
        shift.start = start
        shift.end = end
        shift.date = tzMoment.moment(start).format('MMMM Do, dddd')
        shift.startTime = tzMoment.moment(start).format('h:mma')
        shift.endTime = tzMoment.moment(end).format('h:mma')
        return shift
    },

    roundHalf: (num) => {
        let intPart = Math.trunc(num) // returns 3
        let floatPart = Number((num - intPart).toFixed(2)) // return 0.2
        let rounded = Math.round(floatPart * 2) / 2

        let total = intPart + rounded
        total = total.toFixed(1)

        return total
    },
    getDuration: (end, start) => {
        let hrs = moment.duration(moment(end).diff(moment(start))).asHours()
        // minimum billing hrs is 4 hrs
        let minimumHrs = 4
        if (hrs < minimumHrs) {
            hrs = minimumHrs
        }

        // subtract lunch rates
        if (hrs >= 5) {
            hrs = hrs - 0.5
        }

        hrs = utils.roundHalf(hrs)
        return hrs
    },

    pastelColor: (input_str) => {
        //TODO: adjust base colour values below based on theme
        var baseRed = 128
        var baseGreen = 128
        var baseBlue = 128
        //
        //lazy seeded random hack to get values from 0 - 256
        //for seed just take bitwise XOR of first two chars
        var seed = input_str.charCodeAt(0) ^ input_str.charCodeAt(1)
        var rand_1 = Math.abs(Math.sin(seed++) * 10000) % 256
        var rand_2 = Math.abs(Math.sin(seed++) * 10000) % 256
        var rand_3 = Math.abs(Math.sin(seed++) * 10000) % 256

        //                                        //build colour
        var red = Math.round((rand_1 + baseRed) / 2)
        var green = Math.round((rand_2 + baseGreen) / 2)
        var blue = Math.round((rand_3 + baseBlue) / 2)

        return { red: red, green: green, blue: blue }
    },
    //                                                        }
}

export default utils
