import React from 'react'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
//import InboxIcon from '@mui/icons-material/MoveToInbox'
//mport MailIcon from '@mui/icons-material/Mail'

import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import HomeIcon from '@mui/icons-material/Home'
import MailIcon from '@mui/icons-material/Mail'
//import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore'
//import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
//import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
//import PaymentIcon from '@mui/icons-material/Payment'
import SettingsIcon from '@mui/icons-material/Settings'
import EventIcon from '@mui/icons-material/Event'
import AssignmentIcon from '@mui/icons-material/Assignment'
import IconButton from '@mui/material/IconButton'
import HelpIcon from '@mui/icons-material/Help'
import { Link } from 'react-router-dom'
import LogoutIcon from '@mui/icons-material/ExitToApp'
import { Auth } from 'aws-amplify'

//import StarBorder from '@mui/icons-material/StarBorder'
import AppBar from '@mui/material/AppBar'
import { compose } from 'redux' //Code
import { connect } from 'react-redux' //Code

import { useHistory } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

/*
const useStyles = makeStyles({
    list: {
        width: 250
    },
    fullList: {
        width: 'auto'
    }
})
*/

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: drawerWidth,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    link: {
        color: 'white',
        textDecoration: 'none',
        marginRight: '1em',
    },
    appBar: {
        backgroundColor: 'white',
        boxShadow: 'none',
        zIndex: theme.zIndex.drawer,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        /*
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        */
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        flexGrow: 1,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 0,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}))

const mapStateToProps = (state) => ({ user: state.user })
const mapDispatchToProps = null

const AppDrawer = (props) => {
    const theme = useTheme()
    const classes = useStyles()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    //const isTabletOrDesktop = useMediaQuery(theme.breakpoints.up('sm'))

    const [open, setOpen] = React.useState(false)
    const [path, setPath] = React.useState('/')

    // load on mount
    React.useEffect(() => {
        let startUrl = window.location.pathname.split('/').pop()
        startUrl = '/' + startUrl

        setPath(startUrl)
    }, [])

    const history = useHistory()

    React.useEffect(() => {
        history.listen((location) => {
            setPath(location.pathname)
        })
    }, [history])
    // end set active

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const toggleDrawer = (state) => (event) => {
        console.log('toggling')
        /*
        if (!isDesktop) {
            setOpen(!open)
        }
        */
        setOpen(!open)
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return
        }

        //setState({ ...state, [anchor]: open })
    }

    const logout = () => {
        Auth.signOut()
    }

    const list = (anchor) => (
        <div className={classes.root} role="presentation">
            <List style={{ flexGrow: 1 }}>
                <ListItem
                    selected={path === '/'}
                    button
                    key="Home"
                    component={Link}
                    to="/"
                >
                    <ListItemIcon style={{ color: '#585c89' }}>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem
                    selected={path === '/calendar'}
                    button
                    key="Calendar"
                    component={Link}
                    to="/calendar"
                >
                    <ListItemIcon style={{ color: '#585c89' }}>
                        <EventIcon />
                    </ListItemIcon>
                    <ListItemText primary="Calendar" />
                </ListItem>
                <ListItem
                    selected={path === '/inbox'}
                    button
                    key="Inbox"
                    component={Link}
                    to="/inbox"
                >
                    <ListItemIcon style={{ color: '#585c89' }}>
                        <MailIcon />
                    </ListItemIcon>
                    <ListItemText primary="Inbox" />
                </ListItem>
                <ListItem
                    selected={path === '/history'}
                    button
                    key="Activity"
                    component={Link}
                    to="/history"
                >
                    <ListItemIcon style={{ color: '#585c89' }}>
                        <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Activity" />
                </ListItem>
                <ListItem
                    selected={path === '/settings'}
                    button
                    key="Settings"
                    component={Link}
                    to="/settings"
                >
                    <ListItemIcon style={{ color: '#585c89' }}>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Account" />
                </ListItem>
                <ListItem
                    button
                    key="Help"
                    component="a"
                    target="_blank"
                    href="https://nurselab-nurses.tawk.help/"
                >
                    <ListItemIcon style={{ color: '#585c89' }}>
                        <HelpIcon />
                    </ListItemIcon>
                    <ListItemText primary="Help" />
                </ListItem>
                <ListItem button key="Sign Out" onClick={logout}>
                    <ListItemIcon style={{ color: '#585c89' }}>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sign Out" />
                </ListItem>
            </List>
        </div>
    )

    let topPadding = props.topSafeArea ? props.topSafeArea : 8
    topPadding = topPadding + 'px'
    return (
        <div>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                {false && !isDesktop ? (
                    <Toolbar
                        style={{
                            minHeight: 0,
                            paddingTop: topPadding,
                            paddingBottom: '0.35em',
                        }}
                    >
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                            style={{ flexGrow: 0, color: 'black' }}
                            size="small"
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                ) : (
                    ''
                )}
            </AppBar>

            {!isDesktop ? (
                <Drawer
                    variant="temporary"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 1,
                        [`& .MuiDrawer-paper`]: {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    PaperProps={{
                        sx: {
                            backgroundColor: '#282a3e',
                            color: 'white',
                        },
                    }}
                    className={classes.drawer}
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                    open={open}
                >
                    <div className={classes.toolbar}>
                        <IconButton
                            style={{ color: isDesktop ? '#585c89' : 'inherit' }}
                            onClick={handleDrawerClose}
                            size="large"
                        >
                            {theme.direction === 'rtl' ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronLeftIcon />
                            )}
                        </IconButton>
                    </div>
                    <Divider />
                    <ListItem style={{ fontSize: '1.5em' }}>
                        <span>
                            <img
                                src="/logo_white.png"
                                alt="logo"
                                style={{
                                    height: '1.75em',
                                    color: 'black',
                                    marginRight: '0.35em',
                                    verticalAlign: 'bottom',
                                    display: 'inline-block',
                                }}
                            />

                            <span style={{ fontWeight: 'bolder' }}>
                                NurseLab
                            </span>
                        </span>
                    </ListItem>

                    {list()}
                </Drawer>
            ) : (
                <Drawer
                    variant="permanent"
                    sx={{
                        height: '120%', // todo fix scrolll up and down? test
                        width: drawerWidth,
                        flexShrink: 1,
                        overflow: 'hidden',
                        [`& .MuiDrawer-paper`]: {
                            width: drawerWidth,
                            overflow: 'hidden',
                            boxSizing: 'border-box',
                        },
                    }}
                    PaperProps={{
                        sx: {
                            backgroundColor: '#282a3e',
                            color: 'white',
                        },
                    }}
                    className={classes.drawer}
                >
                    <ListItem style={{ fontSize: '1.5em' }}>
                        <span>
                            <img
                                src="/logo_white.png"
                                alt="logo"
                                style={{
                                    height: '1.75em',
                                    color: 'black',
                                    marginRight: '0.35em',
                                    verticalAlign: 'bottom',
                                    display: 'inline-block',
                                }}
                            />

                            <span style={{ fontWeight: 'bolder' }}>
                                NurseLab
                            </span>
                        </span>
                    </ListItem>
                    {list()}
                </Drawer>
            )}
        </div>
    )
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AppDrawer)
