import React, { useRef, useState } from 'react'
import { connect } from 'react-redux' //Code

import axios from 'axios'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
//import { useDispatch } from 'react-redux'

import AuthService from '../../services/authService.js'
import CircularProgress from '@mui/material/CircularProgress'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import { Camera, CameraResultType } from '@capacitor/camera'

import { decode } from 'base64-arraybuffer'

import { v4 as uuidv4 } from 'uuid'

import {
    openFileDialog,
    closeDialog,
    //    toggleUpload,
    resetUploadToggle,
} from './fileSlice.js'

import { popNavStack, pushNavStack } from '../NavStack/navStackSlice'

import utils from '../../helpers/utils'

const mime = require('mime-types')

const mapStateToProps = (state) => ({
    isOpen: state.file.isOpen,
    resourceType: state.file.resourceType,
    doUpload: state.timesheet.doUpload,
    navStack: state.navStack.stack,
})

const mapDispatchToProps = (dispatch) => {
    return {
        openDialog: () => {
            dispatch(openFileDialog())
        },

        closeDialog: () => {
            dispatch(closeDialog())
        },

        resetUploadToggle: () => {
            dispatch(resetUploadToggle())
        },
        popNavStack: () => {
            dispatch(popNavStack())
        },
        pushNavStack: (item) => {
            dispatch(pushNavStack(item))
        },
    }
}
const resource = {
    bls: 1,
    nurseLicense: 2,
    tb: 4,
    hepatitis: 5,
    flu: 6,
    covid: 7,
}

const File = (props) => {
    //const fileType = props.resourceType
    const fileUpload = useRef(null)

    //const associate = shiftRequest.user

    //console.log(shift)
    const [fileUrl, setFileUrl] = React.useState('')
    const [isFilePDF, setIsFilePDF] = React.useState(false)

    //const [file, setFile] = useState('') // storing the uploaded file
    const [dialogText, setDialogText] = useState('') // storing the uploaded file

    /*
    const handleFileUploadDialogClose = () => {
        setIsFileDialogOpen(false)
    }
    */

    // handle back button + see timsehet slice  for implemntation
    React.useEffect(() => {
        const onPopChange = (e) => {
            let last = props.navStack.slice(-1)[0]

            if (last === 'file' && props.isOpen) {
                props.popNavStack()
                props.closeDialog()
                utils.showTawk()
                window.removeEventListener('popstate', onPopChange)
                return
            }
        }
        window.addEventListener('popstate', onPopChange)
    }, [props.navStack])
    // const [isClockDialogOpen, setIsClockDialogOpen] = React.useState(false)
    const loadFile = async () => {
        // clear dialog text on laod
        setDialogText('Searching Records...')
        setIsLoading(true)
        let headers = await AuthService.getHeader()

        let res
        let resourceType = props.resourceType
        console.log('resourece type ' + resourceType)

        let resourceTypeId = resource[resourceType]
        console.log('resourece type id' + resourceTypeId)

        try {
            res = await axios.get(
                `/api/nurse/file/resource/${resourceTypeId}`,
                {
                    headers: headers,
                }
            )
        } catch (e) {
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }
        setIsLoading(false)
        // returns array of files, however, only 1 file supporte dat this time
        let fileObj = res.data
        let url = ''
        let msg = 'No record available. Please upload.'
        if (fileObj) {
            url = fileObj.urls[0]
            msg = ''
        }
        //setIsFileDialogOpen(true)
        //setFileUrl(url)
        setFileInfo(url)
        setDialogText(msg)
    }
    const setFileInfo = (url) => {
        let tmpUrl = url.split('?')[0]
        if (tmpUrl.substr(-3).toLowerCase() === 'pdf') {
            setIsFilePDF(true)
        } else {
            setIsFilePDF(false)
        }

        setFileUrl(url)
    }
    const handleOpenFileDialog = async () => {
        props.pushNavStack('file')
        utils.hideTawk()
        await loadFile()
        //setIsFileDialogOpen(true)
        return
    }

    const handleCloseFileDialog = () => {
        //props.closeDialog()
        window.history.back()
        //setIsFileDialogOpen(false)
    }

    const getImage = async () => {
        const image = await Camera.getPhoto({
            quality: 80,
            resultType: CameraResultType.Base64,
        })

        // image.webPath will contain a path that can be set as an image src.
        //   // You can access the original file using image.path, which can be
        //     // passed to the Filesystem API to read the raw data of the image,
        //       // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
        var imageUrl = image.webPath
        //
        //           // Can be set to the src of an image now
        //imageElement.src = imageUrl;
        console.log(imageUrl)
        //handleUpload(imageUrl)

        const blob = new Blob([new Uint8Array(decode(image.base64String))], {
            type: `image/${image.format}`,
        })

        handleBlobUpload(blob)
    }

    const handleBlobUpload = async (blob) => {
        //let e = window.event

        let resourceType = props.resourceType
        let resourceTypeId = resource[resourceType]
        setIsLoading(true)
        //const file = e.target.files[0] // accessing file
        //setFile(blob) // storing file

        let fileType = mime.extension(blob.type)

        const data = new FormData()
        let fileName = resourceType + '-' + uuidv4() + '.' + fileType
        console.log(fileName)
        data.append('file', blob, fileName)
        ///NOTE shift == shiftcollection and shiftcollection.shift = shift. confusing! todo fix
        data.append('resourceTypeId', resourceTypeId)

        /*
        for (let propertyName in shift) {
            data.append(propertyName, shift[propertyName])
        }
        */

        let headers = await AuthService.getHeader()
        let res = null
        try {
            res = await axios.post(
                `/api/nurse/file/resource/${resourceTypeId}`,
                //`/api/nurse/file/timesheet`,
                data,
                {
                    headers: headers,
                }
            )
        } catch (e) {
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }

        /*
        try {
            if (res) {
                await UserService.getAccount()
            }
        } catch (e) {
            console.log(e)
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }
        */

        setIsLoading(false)
        await loadFile()
        //handleCloseTimesheetDialog()
        //handleFileUploadDialogClose()
        //reload upload/view timesheets
        //await ShiftService.getAvailableShifts()
    }

    /*
    const handleUpload = async (e) => {
        //let e = window.event

        let resourceType = props.resourceType
        let resourceTypeId = resource[resourceType]
        setIsLoading(true)
        const file = e.target.files[0] // accessing file
        console.log(file)
        setFile(file) // storing file

        const data = new FormData()
        data.append('file', file)
        ///NOTE shift == shiftcollection and shiftcollection.shift = shift. confusing! todo fix
        data.append('resourceTypeId', resourceTypeId)


        let headers = await AuthService.getHeader()
        let res = null
        try {
            res = await axios.post(
                `/api/nurse/file/resource/${resourceTypeId}`,
                //`/api/nurse/file/timesheet`,
                data,
                {
                    headers: headers,
                }
            )
        } catch (e) {
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }


        setIsLoading(false)
        await loadFile()
        //handleCloseTimesheetDialog()
        //handleFileUploadDialogClose()
        //reload upload/view timesheets
        //await ShiftService.getAvailableShifts()
    }
    */

    React.useEffect(() => {
        if (!props.isOpen) {
            // clear prior timesheet image
            setFileUrl('')
            setIsFilePDF(false)
            return
        }
        handleOpenFileDialog()
    }, [props.isOpen])

    React.useEffect(() => {
        if (!props.doUploadTimesheet) {
            // clear prior timesheet image
            return
        }
        //uploadTimesheet()
        props.resetTimesheetUploadToggle()
    }, [props.doUpload])
    const [isLoading, setIsLoading] = React.useState(false)

    /*
    const download = async resourceType => {
        let headers = await AuthService.getHeader()
        setIsLoading(true)
        let res = null
        let filename = null
        let resourceUrl = `/api/nurse/resource/${resourceType}`
        try {
            res = await axios.get(resourceUrl, {
                headers: headers
            })

            setIsLoading(false)
        } catch (e) {
            console.log('error getting file ' + JSON.stringify(e))
            if (e.response.status == 404) {
                alert(
                    'Sorry, file record not found. Please contact support@nurselab.co for 24/7 assistance.'
                )
            }
            setIsLoading(false)

            return
        }

        if (!res) {
            alert(
                'Error downloading file. Please try again later or contact us at support@nurselab.co for 24/7 assistance.'
            )
            return
        }
        let fileObj = res.data
        console.log(JSON.stringify(fileObj))
        // returns array of files, however, only 1 file supporte dat this time
        let url = fileObj.urls[0]
        setFileUrl(url)
        //setIsFileDialogOpen(true)

    }
        */

    const uploadFile = () => {
        fileUpload.current.click()
    }

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xl'))

    return (
        <div>
            {/*
            <input
                type="file"
                accept="image/*;capture=camera"
                onChange={handleUpload}
                hidden
                ref={fileUpload}
            />
            */}
            <Dialog
                fullScreen={fullScreen}
                open={props.isOpen}
                onClose={handleCloseFileDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'xl'}
            >
                <div
                    style={{
                        height: props.topSafeArea + 'px',
                        width: '100%',
                        backgroundColor: 'white',
                    }}
                ></div>
                <DialogTitle id="alert-dialog-title">Viewer</DialogTitle>
                <DialogContent
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: '#F8F8F8',
                    }}
                >
                    <div
                        style={{
                            fontSize: '2em',
                        }}
                    >
                        {dialogText}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        {isFilePDF ? (
                            <embed
                                src={fileUrl}
                                style={{
                                    display: 'block',
                                    margin: 'auto',
                                    minWidth: '30vw',
                                    minHeight: '60vh',
                                    maxWidth: fullScreen ? '100%' : '80vw',
                                    maxHeight: fullScreen ? '100%' : '80vh',
                                }}
                            />
                        ) : (
                            <img
                                src={fileUrl}
                                alt=""
                                style={{
                                    objectFit: 'contain',
                                    maxWidth: fullScreen ? '100%' : '100%',
                                    maxHeight: fullScreen ? '100%' : '100%',
                                }}
                            />
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFileDialog} color="primary">
                        Close
                    </Button>
                    <Button
                        color="primary"
                        autoFocus
                        onClick={() => getImage()}
                    >
                        New Upload
                    </Button>
                    {/*
                    <a
                        href={fileUrl}
                        style={{ textDecoration: 'none' }}
                        download
                    >
                        <Button color="primary" autoFocus>
                            Download
                        </Button>
                    </a>
                    */}
                    {isLoading ? <CircularProgress /> : null}
                </DialogActions>
                <div
                    style={{
                        height: props.bottomSafeArea + 'px',
                        width: '100%',
                        backgroundColor: 'white',
                    }}
                ></div>
            </Dialog>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(File)
