/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
	    "aws_project_region": "us-west-2",
	    "aws_cognito_identity_pool_id": "us-west-2:97679c13-ff61-4bf2-af2b-97a365f56224",
	    "aws_cognito_region": "us-west-2",
	    "aws_user_pools_id": "us-west-2_7lq49V0CI",
	    "aws_user_pools_web_client_id": "2tq9rg60ur9rcvnj0fapk285hh",
	    "oauth": {},
	            cookieStorage: {
			    //required
			    domain: 'nurses.nurselab.co',
			    // number of days
			    expires: 1825


		    }
};


export default awsmobile
