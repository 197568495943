import React from 'react'

import AuthService from '../services/authService.js'
import Container from '@mui/material/Container'

import { setUser } from '../features/User/userSlice.js'
//import userService from '../services/userService.js'

import { compose } from 'redux' //Code
import { connect } from 'react-redux' //Code

import axios from 'axios'

import utils from '../helpers/utils.js'

import CircularProgress from '@mui/material/CircularProgress'
import InboxWidget from '../features/InboxWidget/InboxWidget.js'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import MobileBackButton from '../features/MobileBackButton/MobileBackButton'

import {
    hideBottomNav,
    showBottomNav,
} from '../features/BottomNav/bottomNavSlice'
//const moment = require('moment')

const mapStateToProps = (state) => ({ user: state.user })
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (userState) => {
            dispatch(setUser(userState))
        },
        hideBottomNav: () => {
            dispatch(hideBottomNav())
        },
        showBottomNav: () => {
            dispatch(showBottomNav())
        },
    }
}

const Inbox = (props) => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const [username, setUsername] = React.useState('')
    const [secret, setSecret] = React.useState('')

    //let projectId = 'a0bacc9d-89f0-447a-952d-9ada39b7c9b8'
    let res = null
    const loadChat = async () => {
        let headers = await AuthService.getHeader()
        try {
            res = await axios.put(
                `/api/inbox/credentials/${props.user.id}`,
                null,
                {
                    headers: headers,
                }
            )
        } catch (e) {
            console.log('error loading inbox')
            console.log(e)
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
            return
        }
        let chatCreds = res ? res.data : {}
        //alert(JSON.stringify(chatCreds))
        if (chatCreds.username) {
            setUsername(chatCreds.username)
        }
        if (chatCreds.secret) {
            setSecret(chatCreds.secret)
        }
    }

    React.useEffect(() => {
        loadChat()
        console.log('page load')

        // hide when loading page, show when leaving
        let firstRun = setInterval(function () {
            if (window.Tawk_API && window.Tawk_API.hideWidget) {
                utils.hideTawk()
                clearInterval(firstRun)
            }
        }, 300)
        return () => {
            props.showBottomNav()
            utils.showTawk()
        }
    }, [])

    return (
        <Container
            style={{
                maxWidth: isDesktop ? 'calc(100% - 240px)' : '100%',
                paddingRight: 0,
                paddingLeft: 0,
                '&::WebkitScrollbar': { width: 0, height: 0 },
                height: !isDesktop
                    ? `calc(100vh - 3.5em - ${props.bottomSafeArea}px - ${props.topSafeArea}px )`
                    : 'auto',
                overflowY: !isDesktop ? 'scroll' : 'auto',
            }}
        >
            <MobileBackButton topSafeArea={props.topSafeArea} />
            {username && secret ? (
                <InboxWidget
                    username={username}
                    secret={secret}
                    firstName={props.user.firstName}
                    lastName={props.user.lastName}
                    bottomSafeArea={props.bottomSafeArea}
                    topSafeArea={props.topSafeArea}
                />
            ) : (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        justifyContent: 'center',
                        width: '100vw',
                        height: '100vh',
                    }}
                >
                    <CircularProgress
                        size="8em"
                        style={{ marginTop: '-20vh', textAlign: 'center' }}
                    />
                </div>
            )}
        </Container>
    )
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Inbox)
