import {
    ChatFeedProps,
    ChatHeader,
    MessageList,
    MessageForm,
    //WelcomeGif,
    getDateTime,
    formatDateTime,
    Spinner,
    ChatObject,
} from 'react-chat-engine-advanced'

import React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
//import { styles } from './styles'
import MailOutlineIcon from '@mui/icons-material/MailOutline'

let styles = {}
const getDescription = (
    chat: ChatObject | undefined,
    timezoneOffset: number = 0
) => {
    if (!chat) {
        return ''
    } else if (
        chat.last_message.created &&
        chat.last_message.created.length > 0
    ) {
        const dateTime = getDateTime(chat.last_message.created, timezoneOffset)
        const formattedDateTime = formatDateTime(dateTime)
        return `Last Active ${formattedDateTime}`
    } else {
        return 'Start of conversation'
    }
}

export const ChatFeed: React.FC<ChatFeedProps> = (props: ChatFeedProps) => {
    const { chat } = props
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

    const otherPerson =
        chat &&
        chat.people.find((person) => person.person.username !== props.username)

    /*
    console.log('chat data')
    console.log(chat)
     */

    // create custom title
    let cTitle = ''
    if (chat && chat.custom_json) {
        let tmp = {}
        try {
            tmp = JSON.parse(chat.custom_json)
            if (tmp.facilityName) {
                cTitle = `Re: shift at ${tmp.facilityName} on ${tmp.date} from ${tmp.startTime} to ${tmp.endTime}.`
            }
        } catch (e) {
            console.log('error parsing chat json')
            console.log(e)
            cTitle = chat.title
        }
    } else if (chat && chat.title) {
        cTitle = chat.title
    }

    console.log('title')
    console.log(cTitle)
    const title = props.isLoading ? (
        <Spinner />
    ) : !chat ? (
        ''
    ) : chat.is_direct_chat && otherPerson ? (
        otherPerson.person.username
    ) : (
        //chat.title
        cTitle
    )

    /*
    if (props.renderChatFeed) {
        return <>{props.renderChatFeed(props)}</>
    }
     */

    return (
        <div
            className="ce-chat-feed"
            style={{
                ...styles.style,
                height: !isDesktop && props.isMobileChatListOpen ? 0 : '100%',
                position: 'relative',
                backgroundColor: 'white',
                ...props.style,
            }}
        >
            <ChatHeader
                title={title}
                description={
                    props.isLoading
                        ? 'Loading...'
                        : getDescription(chat, props.timezoneOffset)
                }
                renderChatHeader={props.renderChatHeader}
                /* specify height so text box can be defined */
                style={{
                    // hieght overridden in css
                    paddingTop: !isDesktop ? 0 : '0.5em',
                    ...styles.chatHeaderStyle,
                    ...props.chatHeaderStyle,
                    paddingBottom: '0.5em',
                    //height: !isDesktop ? '10em' : '',
                    overflow: 'hidden',
                }}
            />

            <MessageList
                chat={chat}
                messages={props.messages}
                username={props.username}
                timezoneOffset={props.timezoneOffset}
                hasMoreMessages={props.hasMoreMessages}
                onMessageLoaderShow={props.onMessageLoaderShow}
                onMessageLoaderHide={props.onMessageLoaderHide}
                onBottomMessageShow={props.onBottomMessageShow}
                onBottomMessageHide={props.onBottomMessageHide}
                renderMessageList={props.renderMessageList}
                renderMessage={props.renderMessage}
                bubbleStyle={props.bubbleStyle}
                messageStyle={props.messageStyle}
                style={{
                    ...props.messageListStyle,

                    //...styles.messageListStyle,
                }}
            />

            {props.messages.length === 0 && !props.isLoading && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        flexDirection: 'column',
                    }}
                >
                    <MailOutlineIcon
                        style={{ fontSize: '18em' }}
                        color="disabled"
                    />
                    <div style={{ fontSize: '2em' }}>
                        No messages, you're all caught up!
                    </div>
                </div>
            )}

            {props.messages.length > 0 && (
                <MessageForm
                    label="Compose message..."
                    username={props.username}
                    onSubmit={props.onMessageFormSubmit}
                    renderMessageForm={props.renderMessageForm}
                    style={{
                        width: '100%',
                        position: isDesktop ? 'absolute' : 'fixed',
                        //position: 'fixed',  // bad because width gets screwed up on DESKTOP but fixed better for mobile
                        paddingTop: '0.5em',
                        borderTop: '1px',
                        borderBottom: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderStyle: 'solid',
                        borderColor: 'lightgray',
                        //@ts-ignore
                        bottom: props.bottomSafeArea + 'px',

                        //...styles.messageFormStyle,
                        ...props.messageFormStyle,
                    }}
                />
            )}
        </div>
    )
}
