import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
//import AppBar from '@mui/material/AppBar'
//import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
//import Typography from '@mui/material/Typography'

import UserService from './services/userService.js'
import AppDrawer from './AppDrawer.js'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const useStyles = makeStyles((theme) => ({
    root: {
        //flexGrow: 1
        flexGrow: 0,
        // only for tablets & DESKTOPS
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(1),
            width: 240,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    link: {
        color: 'white',
        textDecoration: 'none',
        marginRight: '1em',
    },
}))

const mapStateToProps = (state) => ({ user: state.user })

const NavBar = (props) => {
    const theme = useTheme()
    //const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isTabletOrDesktop = useMediaQuery(theme.breakpoints.up('sm'))
    const hasCompletedOnboarding =
        UserService.hasNurseLabStaffingRegistrationAgreement(props.user)
            ? true
            : false
    const classes = useStyles()
    const logout = () => {
        Auth.signOut()
    }

    return (
        <div className={classes.root}>
            {hasCompletedOnboarding ? (
                <AppDrawer topSafeArea={props.topSafeArea} />
            ) : isTabletOrDesktop ? (
                <div
                    style={{
                        position: 'absolute',
                        top: '1em',
                        left: '1em',
                        zIndex: 100000000,
                    }}
                >
                    <Button
                        key="Sign Out"
                        label="Sign Out"
                        onClick={logout}
                        color="secondary"
                        size="large"
                        variant="contained"
                    >
                        Sign Out
                    </Button>
                </div>
            ) : (
                ''
            )}
            {/*
            <AppBar position="fixed">
                <Toolbar>
                    {hasCompletedOnboarding ? <AppDrawer /> : null}
                    <Typography variant="h6" className={classes.title}>
                    </Typography>
                    {!isDesktop ? (
                        <Button
                            key="Sign Out"
                            label="Sign Out"
                            onClick={logout}
                            color="secondary"
                            size="large"
                            variant="contained"
                        >
                            Sign Out
                        </Button>
                    ) : (
                        ''
                    )}
                </Toolbar>
            </AppBar>
    */}
        </div>
    )
}

export default compose(connect(mapStateToProps, null))(NavBar)
