import React, { useEffect } from 'react'
import { useTheme } from '@mui/material/styles'

import Paper from '@mui/material/Paper'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material//BottomNavigationAction'

import HomeIcon from '@mui/icons-material/Home'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety'
import EventIcon from '@mui/icons-material/Event'
import AssignmentIcon from '@mui/icons-material/Assignment'
import { Link } from 'react-router-dom'

import { compose } from 'redux' //Code
import { connect } from 'react-redux' //Code

import { useHistory } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'

import { SafeArea } from 'capacitor-plugin-safe-area'
import MenuIcon from '@mui/icons-material/Menu'

const mapStateToProps = (state) => ({
    user: state.user,
    doShow: state.bottomNav.doShow,
})
const mapDispatchToProps = null

const BottomNav = (props) => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    const [value, setValue] = React.useState(0)

    const [bottomSafeArea, setBottomSafeArea] = React.useState(0)

    useEffect(() => {
        SafeArea.getSafeAreaInsets().then(({ insets }) => {
            setBottomSafeArea(insets.bottom)
        })
    }, [SafeArea])

    // set active button
    const setActive = (url) => {
        switch (url) {
            case '/':
                setValue(0)
                break
            case '/calendar':
                setValue(1)
                break
            case '/history':
                setValue(2)
                break
            case '/menu':
                setValue(3)
                break
            default:
                setValue('')
                break
        }
    }
    // load on mount
    useEffect(() => {
        let startUrl = window.location.pathname.split('/').pop()
        startUrl = '/' + startUrl

        setActive(startUrl)
    }, [])

    const history = useHistory()

    useEffect(() => {
        history.listen((location) => {
            setActive(location.pathname)
        })
    }, [history])
    // end set active

    return (
        <>
            {!isDesktop ? (
                <Paper
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: 'white',
                        zIndex: 1000,
                        display: props.doShow ? 'block' : 'none',
                        paddingBottom: bottomSafeArea + 'px',
                    }}
                    elevation={3}
                >
                    <BottomNavigation
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {}}
                    >
                        <BottomNavigationAction
                            label="Get Shifts"
                            component={Link}
                            to="/"
                            icon={<HealthAndSafetyIcon />}
                        />
                        <BottomNavigationAction
                            component={Link}
                            to="/calendar"
                            label="Calendar"
                            icon={<EventIcon />}
                        />
                        <BottomNavigationAction
                            component={Link}
                            to="/history"
                            label="Activity"
                            icon={<AssignmentIcon />}
                        />
                        <BottomNavigationAction
                            component={Link}
                            to="/menu"
                            label="Menu"
                            icon={<MenuIcon />}
                        />
                    </BottomNavigation>
                </Paper>
            ) : (
                ''
            )}
        </>
    )
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(BottomNav)
