// TODO ability to add /remove facility
// ability to update multiple facilities

import React, { useState } from 'react'

import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Avatar from '@mui/material/Avatar'
import CircularProgress from '@mui/material/CircularProgress'

import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import axios from 'axios'
import { connect } from 'react-redux' //Code
import UserService from '../services/userService.js'
import AuthService from '../services/authService.js'
import { setUser } from '../features/User/userSlice.js'

import { openFileDialog } from '../features/File/fileSlice'
import Reviews from '../features/Reviews/Reviews'
import MobileBackButton from '../features/MobileBackButton/MobileBackButton'

import { Camera, CameraResultType } from '@capacitor/camera'
import { decode } from 'base64-arraybuffer'
import { v4 as uuidv4 } from 'uuid'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import moment from 'moment'

class UserSettings {
    constructor(user) {
        this.id = user.id ? user.id : ''
        this.firstName = user.firstName ? user.firstName : ''
        this.lastName = user.lastName ? user.lastName : ''
        this.address = user.address ? user.address : ''
        this.email = user.email ? user.email : ''
        this.phone = user.phone ? user.phone : ''
        this.city = user.city ? user.city : ''
        this.zipcode = user.zipcode ? user.zipcode : ''
        this.state = user.state ? user.state : ''
        this.profileImageUrl = user.profileImageUrl ? user.profileImageUrl : ''
        this.isEmailEnabled = user.isEmailEnabled ? user.isEmailEnabled : false
        this.isSmsEnabled = user.isSmsEnabled ? user.isSmsEnabled : false
    }
}
const styles = {
    textField: {
        width: '100%',
        marginBottom: '0.75em',
    },
}

const mapStateToProps = (state) => ({ user: state.user })
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (userState) => {
            dispatch(setUser(userState))
        },

        openFileDialog: (resourceType) => {
            dispatch(openFileDialog({ resourceType: resourceType }))
        },
    }
}
const Settings = (props) => {
    //const fileUpload = useRef(null)
    //const [file, setFile] = useState('') // storing the uploaded file
    const [userSettings, setUserSettings] = useState(
        new UserSettings(props.user)
    )
    const [isLoading, setIsLoading] = useState(false)

    // on page load scroll to top, fix buggy nav
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleInputChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        //console.log('name ' + name)
        setUserSettings({
            ...userSettings,
            [name]: value,
        })
    }

    const handleBooleanInputChange = (event) => {
        const name = event.target.name
        let value = event.target.value
        console.log('name ' + name + ' value ' + value)
        console.log(!userSettings[name])
        setUserSettings({
            ...userSettings,
            [name]: !userSettings[name],
        })
    }

    /*
    const handleFileChange = (e) => {
        const file = e.target.files[0] // accessing file
        console.log(file)
        //setFile(file) // storing file

        // auto save user data on file upload
        updateUserSettings()
    }
    */

    const getImage = async () => {
        const image = await Camera.getPhoto({
            quality: 80,
            resultType: CameraResultType.Base64,
        })

        // image.webPath will contain a path that can be set as an image src.
        //   // You can access the original file using image.path, which can be
        //     // passed to the Filesystem API to read the raw data of the image,
        //       // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
        var imageUrl = image.webPath
        //
        //           // Can be set to the src of an image now
        //imageElement.src = imageUrl;
        console.log(imageUrl)
        //handleUpload(imageUrl)

        const blob = new Blob([new Uint8Array(decode(image.base64String))], {
            type: `image/${image.format}`,
        })

        let fileName = 'profile-image-' + uuidv4() + '.' + image.format

        let imageFile = new File([blob], fileName, {
            lastModified: moment().unix(),
            type: blob.type,
        })

        // DEPRECATED unused?
        //setFile(imageFile)

        updateUserSettings(imageFile)
    }

    const handleOpenFileDialog = async (resourceType) => {
        props.openFileDialog(resourceType)
    }

    const submitForm = async (e) => {
        e.preventDefault()
        updateUserSettings()
    }

    // TODO only update profile image if profile image is changed
    const updateUserSettings = async (imageFile) => {
        console.log(userSettings.name)
        //console.log(this.state)
        setIsLoading(true)
        let res = null

        const data = new FormData()
        //optional attachment
        if (imageFile) {
            data.append('image', imageFile)
        }
        for (let propertyName in userSettings) {
            data.append(propertyName, userSettings[propertyName])
        }

        let headers = await AuthService.getHeader()
        try {
            res = await axios.put(`/api/nurse/account/${props.user.id}`, data, {
                headers: headers,
            })
        } catch (e) {
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }
        try {
            if (res) {
                let tmpUser = await UserService.getAccount()
                //reload settings /images
                setUserSettings(new UserSettings(tmpUser))
            }
        } catch (e) {
            console.log(e)
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }
        setIsLoading(false)
    }
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    return (
        <Container
            style={{
                maxWidth: '100%',
                width: '100%',
                marginLeft: isDesktop ? '3em' : '0',
                marginRight: isDesktop ? '3em' : '0',
                marginTop: isDesktop ? '2em' : '1em',
                height: !isDesktop
                    ? `calc(100vh - 3.5em - ${props.bottomSafeArea}px - ${props.topSafeArea}px )`
                    : 'auto',
                overflowY: !isDesktop ? 'scroll' : 'auto',
            }}
        >
            <MobileBackButton topSafeArea={props.topSafeArea} />
            <h2>Account</h2>
            <br />
            {/*
            <input
                type="file"
                accept="image/*;capture=camera"
                capture="camera"
            />
            */}

            <form onSubmit={submitForm}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <TextField
                            id=""
                            value={userSettings.firstName}
                            style={styles.textField}
                            onChange={handleInputChange}
                            label="First Name"
                            name="firstName"
                            required
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <TextField
                            id=""
                            value={userSettings.lastName}
                            style={styles.textField}
                            onChange={handleInputChange}
                            label="Last Name"
                            name="lastName"
                            required
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3} style={{ display: 'none' }}>
                        <TextField
                            id=""
                            value={userSettings.email}
                            style={styles.textField}
                            onChange={handleInputChange}
                            label="Email"
                            name="email"
                            required
                            disabled
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            id=""
                            value={userSettings.phone}
                            style={styles.textField}
                            onChange={handleInputChange}
                            label="Phone"
                            name="phone"
                            required
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={9}>
                        <TextField
                            style={styles.textField}
                            value={userSettings.address}
                            onChange={handleInputChange}
                            label="Address"
                            name="address"
                            required
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <TextField
                            value={userSettings.city}
                            onChange={handleInputChange}
                            style={styles.textField}
                            label="City"
                            name="city"
                            required
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            value={userSettings.state}
                            style={styles.textField}
                            onChange={handleInputChange}
                            label="State"
                            name="state"
                            required
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            style={styles.textField}
                            value={userSettings.zipcode}
                            onChange={handleInputChange}
                            label="Zipcode"
                            name="zipcode"
                            required
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <br />
                <br />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h6" gutterBottom>
                            Profile Picture
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <Avatar
                            alt=""
                            style={{ height: '5em', width: '5em' }}
                            src={userSettings.profileImageUrl}
                            onClick={() => getImage()}
                        />
                        <br />
                        <Button
                            color="primary"
                            variant="contained"
                            component="label"
                            onClick={() => getImage()}
                        >
                            New Upload
                        </Button>
                        <br />
                        <br />
                        {isLoading ? <CircularProgress /> : null}
                    </Grid>
                </Grid>
                <br />
                <br />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h6" gutterBottom>
                            Records
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        {/*
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleUpload}
                            hidden
                            ref={resourceUpload}
                        />
                        */}
                        {/*
                            onClick={() => resourceUpload.current.click()}
                            */}
                        <Button
                            onClick={() => handleOpenFileDialog('bls')}
                            style={styles.textField}
                            label="save"
                            variant="contained"
                            disabled={isLoading}
                        >
                            Update BLS
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button
                            style={styles.textField}
                            onClick={() => handleOpenFileDialog('tb')}
                            label="save"
                            variant="contained"
                            disabled={isLoading}
                        >
                            TB Test Record
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button
                            style={styles.textField}
                            label="save"
                            variant="contained"
                            disabled={isLoading}
                            onClick={() => handleOpenFileDialog('hepatitis')}
                        >
                            Hepatitis Vaccination Record
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button
                            style={styles.textField}
                            label="save"
                            variant="contained"
                            onClick={() => handleOpenFileDialog('flu')}
                            disabled={isLoading}
                        >
                            Flu Immunization Record
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button
                            style={styles.textField}
                            label="save"
                            variant="contained"
                            onClick={() => handleOpenFileDialog('covid')}
                            disabled={isLoading}
                        >
                            Covid Vaccine Record
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button
                            style={styles.textField}
                            label="save"
                            variant="contained"
                            onClick={() => handleOpenFileDialog('nurseLicense')}
                            disabled={isLoading}
                        >
                            Nurse License
                        </Button>
                    </Grid>
                </Grid>
                <br />
                <br />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h6" gutterBottom>
                            Reviews
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <Reviews
                            userId={
                                props.user && props.user.id
                                    ? props.user.id
                                    : null
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <br />
                        <Typography variant="h6" gutterBottom>
                            Notifications
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={userSettings.isEmailEnabled}
                                    onChange={handleBooleanInputChange}
                                    name="isEmailEnabled"
                                />
                            }
                            label="Email Notifications"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={userSettings.isSmsEnabled}
                                    onChange={handleBooleanInputChange}
                                    name="isSmsEnabled"
                                    disabled={
                                        userSettings.isInternallyAdministered
                                    }
                                />
                            }
                            label="Sms Notifications (can not be enabled if account is administered by NurseLab)"
                        />
                    </Grid>
                </Grid>
                <br />
                <br />
                <br />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={2}>
                        <Button
                            type="submit"
                            style={styles.textField}
                            label="save"
                            variant="contained"
                            color="primary"
                            disabled={isLoading}
                        >
                            Save
                        </Button>
                        {isLoading ? <CircularProgress /> : null}
                    </Grid>
                </Grid>
            </form>
            <br />
            <br />
            <br />
        </Container>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
