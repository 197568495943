/*
 *
 *
 *
 * NOTE UNUSED: 22/5/01
 *
 */
import React from 'react'

import { withRouter } from 'react-router-dom'
import Container from '@mui/material/Container'

import { compose } from 'redux' //Code
import { connect } from 'react-redux' //Code
import AuthService from '../services/authService.js'
import { setUser } from '../features/User/userSlice.js'

import axios from 'axios'

const mapStateToProps = (state) => ({ user: state.user })
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (userState) => {
            dispatch(setUser(userState))
        },
    }
}

/*todo function below is called in home.js, simplify*/

const SignupThankYou = (props) => {
    console.log('TESTING ')
    React.useEffect(() => {
        console.log('user effect')
        async function onboard() {
            console.log('TESTING')

            let headers = await AuthService.getHeader()
            let data = { isOnboarded: true }
            try {
                await axios.post(`/api/nurse/account/onboard`, data, {
                    headers: headers,
                })
            } catch (e) {
                window.alert(
                    'Sorry, there was an error. Please try again in a few seconds.'
                )
            }

            props.history.push('/')
        }
        console.log('test1')
        onboard()

        console.log('test2')
    }, [props.history])

    return (
        <Container>
            <h2>Thanks for signing up!</h2>
            Hi {props.user.firstName}! Thank you for signing up.
            <br />
            <br />
            {/*
            <iframe src={'https://nurselab.co/thank-you-sign-up'} />
            */}
        </Container>
    )
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(SignupThankYou)
