import { createSlice } from '@reduxjs/toolkit'

export const mailModalSlice = createSlice({
    name: 'mailModal',
    initialState: {
        isOpen: false,
        shiftCollection: {},
    },
    reducers: {
        openMailModal: (state, action) => {
            console.log(action.payload)

            state = { ...state, isOpen: true, shiftCollection: action.payload }

            return state
        },
        closeMailModal: (state, action) =>
            (state = { ...state, isOpen: false }),
    },
})

export const isOpen = (state) => state
export const { openMailModal, closeMailModal } = mailModalSlice.actions

export default mailModalSlice.reducer
