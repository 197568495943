import axios from 'axios'
import store from '../app/store'
import { setUser } from '../features/User/userSlice.js'

import AuthService from './authService.js'

const UserService = {
    getAccount: async function (accessToken) {
        let headers = ''
        if (!accessToken) {
            headers = await AuthService.getHeader()
        } else {
            headers = { Authorization: 'Bearer ' + accessToken }
        }
        let user = null
        let res = await axios.get('/api/nurse/account/', {
            headers: headers,
        })
        if (res) {
            user = res.data
        }

        console.log('user dump from get account')
        console.log(user)

        store.dispatch(setUser(user))
        return user
    },
    isJotformOnboarded: (user) => {
        console.log('is profile completed test')
        if (user && user.profile && user.profile.isJotformOnboarded) {
            return true
        }
        console.log('user profile does not exist')
        return false
    },
    hasNurseLabStaffingRegistrationAgreement: (user) => {
        console.log('is profile completed test')
        if (
            user &&
            user.profile &&
            user.profile.hasNurseLabStaffingRegistrationAgreement
        ) {
            return true
        }
        console.log('user profile does not exist')
        return false
    },
}

export default UserService
