import React, { useEffect } from 'react'

import axios from 'axios'

import Rating from '@mui/material/Rating'
//import { useDispatch } from 'react-redux'

import AuthService from '../../services/authService.js'
//import CircularProgress from '@mui/material/CircularProgress'

const moment = require('moment')

const Reviews = (props) => {
    //const [isLoading, setIsLoading] = React.useState(false)
    //const [clockDetails, setClockDetails] = React.useState('')
    const [renderedReviews, setRenderedReviews] = React.useState('')
    const loadReviews = async () => {
        try {
            setRenderedReviews('Loading...')

            let headers = await AuthService.getHeader()
            let res = await axios.get(`/api/review/associate/${props.userId}`, {
                headers: headers,
            })
            let reviewObjs = res.data
            if (reviewObjs.length == 0) {
                setRenderedReviews('No Reviews Yet from Facilities.')
            } else {
                let reviews = []
                for (let [idx, reviewObj] of reviewObjs.entries()) {
                    if (reviewObj.rating) {
                        let tmp = (
                            <Rating
                                key={idx}
                                name="rating"
                                readOnly
                                style={{ verticalAlign: 'bottom' }}
                                value={parseInt(reviewObj.rating)}
                            />
                        )
                        reviews.push(tmp)
                    }

                    /* not timezone aware, w/e */
                    let reviewDate = moment(reviewObj.created).format('ll')
                    reviews.push(
                        <>
                            <span>
                                {' '}
                                "<i>{reviewObj.review}</i>" by{' '}
                                {reviewObj.facility.name}, {reviewDate}
                            </span>
                            <br />
                            <br />
                        </>
                    )

                    //tmp += ' -' + reviewObj.review
                    //tmp += ' -- facility name'
                }
                setRenderedReviews(reviews)
            }
        } catch (e) {
            console.log(e)
            alert(
                'Sorry, there was an error. Please contact us at support@nurselab.co. Thank you!'
            )
        }
    }

    useEffect(() => {
        // load init data
        if (!props.userId) {
            return
        }
        loadReviews()
    }, [props.userId])

    return renderedReviews
}

export default Reviews
