import React from 'react'
import { connect } from 'react-redux' //Code

//import { openModal } from '../ShiftModal/shiftModalSlice'
//import ShiftModal from '../ShiftModal/ShiftModal'

import CardShift from '../CardShift/CardShift.js'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'

import Grid from '@mui/material/Grid'

const moment = require('moment')

const mapStateToProps = (state) => ({
    user: state.user,
    shiftCollections: state.shiftCollections,
})

const CardShifts = (props) => {
    if (!props.shiftCollections) {
        return null
    }

    //todo supdate shifts to shift collection?
    let shiftCollections = props.shiftCollections

    let currentShifts = []
    let historicShifts = []
    let unselectedShifts = []
    let currentItems = []
    let historicItems = []
    let unselectedItems = []
    let items = []

    shiftCollections.forEach((x) => {
        if (x.isFilled && !x.shift) {
            unselectedShifts.push(x)
        } else if (moment(x.end).isAfter(new Date())) {
            currentShifts.push(x)
        } else {
            historicShifts.push(x)
        }
    })

    currentItems = currentShifts.map((shiftCollection, index) => {
        return (
            <CardShift
                shiftCollection={shiftCollection}
                key={shiftCollection.id}
            />
        )
    })

    historicItems = historicShifts.map((shiftCollection, index) => {
        return (
            <CardShift
                shiftCollection={shiftCollection}
                key={shiftCollection.id}
            />
        )
    })

    unselectedItems = unselectedShifts.map((shiftCollection, index) => {
        return (
            <CardShift
                shiftCollection={shiftCollection}
                key={shiftCollection.id}
            />
        )
    })
    // show most recent passed shifts first
    unselectedItems.reverse()
    historicItems.reverse()

    items.push(
        <div
            key="upcomingShiftsHeader"
            style={{ width: '100%', marginLeft: '1em' }}
        >
            <h2>Available Shifts</h2>
        </div>
    )
    if (currentItems.length === 0) {
        items.push(
            <h3 key="noUpcomingShifts" style={{ marginLeft: '0.85em' }}>
                No shifts available to request at this time. Please check back
                soon.
            </h3>
        )
    } else {
        items = items.concat(currentItems)
    }

    items.push(
        <div
            key="pastShiftsHeader"
            style={{ width: '100%', marginLeft: '1em' }}
        >
            <br />
            <h2>Prior Shifts</h2>
        </div>
    )
    if (historicItems.length === 0) {
        items.push(
            <h3 key="noPastShifts" style={{ marginLeft: '0.85em' }}>
                No past shifts.
            </h3>
        )
    } else {
        items = items.concat(historicItems)
    }

    // unselected shifts
    if (unselectedItems.length > 0) {
        items.push(
            <div
                key="unselectedShiftsHeader"
                style={{
                    width: '100%',
                    marginTop: '2em',
                    marginLeft: '0.6em',
                    marginRight: '0.6em',
                }}
            >
                <Accordion
                    style={{
                        backgroundColor: '#f9f9f9',
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography
                            style={{ width: '100%', marginLeft: '1em' }}
                        >
                            View Unselected Shifts (last 90 days)
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            {unselectedItems}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </div>
        )
        //items = items.concat(unselectedItems)
    }
    return items
}

export default connect(mapStateToProps, null)(CardShifts)
