import React from 'react'
//import { useDispatch, useSelector } from 'react-redux'
//import plus_icon from "../../plus_icon.png";

import Button from '@mui/material/Button'
//import { makeStyles } from '@mui/material/styles'

import { openMailModal, closeMailModal } from './mailModalSlice'

import { connect } from 'react-redux' //Code

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'

import { openSnackbarMessage } from '../SnackbarMessage/snackbarMessageSlice'
import AuthService from '../../services/authService.js'
import axios from 'axios'

const mapStateToProps = (state) => ({
    isOpen: state.mailModal.isOpen,
    shiftCollection: state.mailModal.shiftCollection,
})
const mapDispatchToProps = (dispatch) => {
    return {
        openMailModal: () => {
            dispatch(openMailModal())
        },

        closeMailModal: () => {
            dispatch(closeMailModal())
        },
        openSnackbarMessage: (data) => {
            dispatch(openSnackbarMessage(data))
        },
    }
}

const MailModal = (props) => {
    let shiftCollection = props.shiftCollection
    const [isLoading, setIsLoading] = React.useState(false)

    const [message, setMessage] = React.useState('')

    const handleClose = () => {
        props.closeMailModal()
    }
    const handleSendMessage = async () => {
        setIsLoading(true)
        let headers = await AuthService.getHeader()

        let data = {}

        data.shiftCollection = JSON.parse(JSON.stringify(shiftCollection))
        data.message = message
        let res = null
        console.log(JSON.stringify(data))

        try {
            res = await axios.post(`/api/inbox/nurse/message/`, data, {
                headers: headers,
            })
        } catch (e) {
            setIsLoading(false)
            console.log('error sending msg')
            console.log(e)
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds or please contact customer support at support@nurselab.co.'
            )
            return
        }

        setIsLoading(false)
        props.closeMailModal()
        let msg = `Message successful sent to ${shiftCollection.facilityName}.`
        props.openSnackbarMessage({
            isOpen: true,
            message: msg,
            alertType: 'success',
        })
    }

    return (
        <div>
            <Dialog
                open={props.isOpen}
                onClose={handleClose}
                fullWidth={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Message Facility
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Send a message to {shiftCollection.facilityName}{' '}
                        regarding the shift on{' '}
                        <b>
                            {shiftCollection.date}, {shiftCollection.startTime}{' '}
                            to {shiftCollection.endTime}.
                        </b>
                    </DialogContentText>
                    <TextField
                        rows={3}
                        multiline
                        maxRows={5}
                        onChange={(e) => {
                            setMessage(e.target.value)
                        }}
                        style={{ width: '100%', marginTop: '1em' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isLoading}
                        onClick={handleClose}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isLoading}
                        onClick={handleSendMessage}
                        color="primary"
                        autoFocus
                    >
                        Send Message
                    </Button>
                    {isLoading ? <CircularProgress /> : null}
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(MailModal)
