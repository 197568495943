import React from 'react'

import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import { Calendar, momentLocalizer, Navigate } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
//import moment from 'moment'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

import { connect } from 'react-redux' //Code

import CardShift from '../features/CardShift/CardShift.js'

import * as dates from 'date-arithmetic'

import TimeGrid from 'react-big-calendar/lib/TimeGrid'

import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import RestoreIcon from '@mui/icons-material/Restore'

import {
    peakBackNavStack,
    popNavStack,
    pushNavStack,
} from '../features/NavStack/navStackSlice'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import TzMoment from '../plugins/TzMoment'

//const localizer = momentLocalizer(moment)

//COLOR SCHEME
const CONFIRMED_BG_COLOR = 'green'
const CANCELED_BG_COLOR = 'gray'
const PENDING_BG_COLOR = 'teal'
const AVAILABLE_BG_COLOR = 'orange'
const DECLINED_BG_COLOR = '#ffdcdc'
const NOSHOW_BG_COLOR = '#fababa'

/*
const ColoredDateCellWrapper = ({ children }) =>
    React.cloneElement(React.Children.only(children), {
        style: {
            backgroundColor: 'lightblue',
        },
    })
    */

const mapStateToProps = (state) => ({
    user: state.user,
    shifts: state.shiftCollections,
    navStack: state.navStack,
})
const mapDispatchToProps = (dispatch) => {
    return {
        peakBackNavStack: () => {
            dispatch(peakBackNavStack())
        },
        popNavStack: () => {
            dispatch(popNavStack())
        },
        pushNavStack: (item) => {
            dispatch(pushNavStack(item))
        },
    }
}

const threeDayWeek = (props) => {
    const range = (date) => {
        let start = date
        let end = dates.add(start, 2, 'day')

        let current = start
        let range = []

        while (dates.lte(current, end, 'day')) {
            range.push(current)
            current = dates.add(current, 1, 'day')
        }

        return range
    }
    let newRange = range(props.date)
    /*
    this.navigate = (date, action) => {
        switch (action) {
            case Navigate.PREVIOUS:
                return dates.add(date, -3, 'day')

            case Navigate.NEXT:
                return dates.add(date, 3, 'day')

            default:
                return date
        }
    }
    */
    return <TimeGrid {...props} range={newRange} eventOffset={15} />
}
threeDayWeek.title = (date) => {
    return `${date.toLocaleDateString()} - ${dates
        .add(date, 3, 'day')
        .toLocaleDateString()}`
}
threeDayWeek.navigate = (date, action) => {
    switch (action) {
        case Navigate.PREVIOUS:
            return dates.add(date, -3, 'day')

        case Navigate.NEXT:
            return dates.add(date, 3, 'day')

        default:
            return date
    }
}

const MyCalendar = (props) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [events, setEvents] = React.useState([])
    const [resourceMap, setResourceMap] = React.useState([])
    const [selectedShift, setSelectedShift] = React.useState({})
    const [lastView, setLastView] = React.useState('month')

    // TODO smarter way to set timezone?? for now use pacific time
    let timezone = 'America/Los_Angeles'
    if (props.user.timezone) {
        timezone = props.user.timezone
    }
    let tzMoment = new TzMoment(timezone)
    let localizer = momentLocalizer(tzMoment.moment)
    localizer.segmentOffset = 0

    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const switchView = (viewName, forceReRender) => {
        console.log('cal ref')
        // nodefined on init
        /*
        if (!calendarRef) {
            viewName = 'month'
            console.log('no cal ref, first load?')
        } else {
            console.log(calendarRef.current.props.view)
        }
        */
        // set view name to month if not existant
        //viewName = 'month'
        /*
        if (!viewName) {
            //let selectedFacility = props.user.selectedFacility
            let shifts = props.shifts
            if (shifts.length > 0) {
                viewName = shifts[0].eventView
            }
            if (!viewName) {
                viewName = 'month'
            }
        }
        */
        if (!viewName) {
            viewName = lastView
        }
        let tmpEvents = []

        let tmpResourceMap = []
        /*
        if (viewName === 'work_week') {
            tmpResourceMap = null
        } else {
            tmpResourceMap = [
                { resourceId: 'CNA', resourceTitle: 'CNA' },
                { resourceId: 'LVN', resourceTitle: 'LVN' },
                { resourceId: 'RN', resourceTitle: 'RN' }
            ]
        }
        */
        setResourceMap(tmpResourceMap)

        console.log('view rerendering ' + viewName)

        if (viewName === 'month') {
            tmpEvents = calendarShiftView(viewName)
        } else {
            tmpEvents = tableShiftView(viewName)
        }
        console.log(tmpEvents.length)
        console.log(tmpEvents)
        setEvents(tmpEvents)
        setLastView(viewName)
    }

    React.useEffect(() => {
        // re-render w/ proper description
        let tmpEvents = calendarShiftView(lastView)
        setEvents(tmpEvents)
    }, [isDesktop])

    const calendarShiftView = (viewName) => {
        let shifts = props.shifts
        let tmp = shifts.map((x, i) => {
            let y = Object.assign({}, x)
            let start = tzMoment.moment(y.start)
            let end = tzMoment.moment(y.end)
            y.date = start.format('MMMM Do, dddd')
            let startM = start.minute()
            if (startM === 0) {
                y.startTime = start.format('h')
            } else {
                y.startTime = start.format('h:mm')
            }

            let endM = end.minute()
            if (endM === 0) {
                y.endTime = end.format('ha')
            } else {
                y.endTime = end.format('h:mma')
            }
            y.start = new Date(start)
            y.allDay = false
            y.end = new Date(end)
            if (isDesktop) {
                y.title = y.startTime + '-' + y.endTime + ': ' + y.facilityName //console.log(start, y.date)
            } else {
                y.title = y.startTime + '-' + y.endTime
            }
            y.resourceId = y.type
            y.eventView = viewName

            y.isFilled = false
            if (y.isCancelled) {
            } else if (y.isBooked) {
                y.isFilled = true
            }
            return y
        })
        return tmp
    }
    const tableShiftView = (viewName) => {
        let shifts = props.shifts
        let tmp = shifts.map((x, i) => {
            let y = Object.assign({}, x)
            let start = tzMoment.moment(y.start)
            let end = tzMoment.moment(y.end)
            y.date = start.format('MMMM Do, dddd')
            y.startTime = start.format('h:mma')
            y.endTime = end.format('h:mma')
            y.start = new Date(start)
            y.allDay = false
            y.end = new Date(end)
            y.resourceId = y.type

            y.title = ''
            //TODO add isFilled check
            // assumed unfilled
            if (y.isNoShow) {
                y.title += '(NO-SHOW)'
            } else if (y.isDeclined) {
                y.title += '(DECLINED)'
            } else if (y.isCanceled) {
                y.title += '(CANCELED)'
            } else if (y.isConfirmed) {
                y.title += `Confirmed: ${y.facilityName}`
            } else if (y.isPending) {
                y.title += `Requested shift. Awaiting response from ${y.facilityName}`
            } else {
                y.title += `Available shift | ${y.facilityName} (${y.startTime} - ${y.endTime})`
            }

            y.eventView = viewName
            return y
        })
        return tmp
    }

    // on page load scroll to top, fix buggy nav
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    React.useEffect(() => {
        switchView(null, true)
        //setEvents(tmp)
    }, [props.user])

    React.useEffect(() => {
        // REFRESH job listing view
        if (selectedShift && selectedShift.id && props.shifts) {
            let shiftCollection = props.shifts.find(
                (x) => x.id == selectedShift.id
            )
            //shift collection
            // reconnect it to store
            console.log('found shift collection ' + shiftCollection)
            setSelectedShift(shiftCollection)

            switchView(null, true)
        }
    }, [props.shifts, selectedShift])
    const calendarRef = React.useRef()
    const handleClose = () => {
        window.history.back()
    }
    const onSelectEvent = (event) => {
        console.log('on select event')
        console.log(event)
        setSelectedShift(event)

        //required for back key nav, save change to history
        props.pushNavStack('cardShift')

        if (event.isCanceled) {
            setIsOpen(true)
            /*} else if (event.isFilled) {
            setIsOpenViewShift(true)
            */
        } else {
            setIsOpen(true)
        }
        //this.handleOpen()
    }
    // handle back button + see timsehet slice  for implemntation
    // limitation closes all cascading modals that are open
    React.useEffect(() => {
        const onPopChange = (e) => {
            if (!props.navStack.isUnlocked) {
                return
            }
            let last = props.navStack.stack.slice(-1)[0]
            if (last === 'cardShift' && isOpen) {
                console.log('cardshift pop card shift |' + last)
                props.popNavStack()
                setIsOpen(false)
                window.removeEventListener('popstate', onPopChange)
                return
            }
        }
        window.addEventListener('popstate', onPopChange)
    }, [props.navStack.stack, props.navStack.isUnlocked])

    //entire day cell color change
    const customDayPropGetter = (date) => {
        //let d = new Date()

        let today = new Date()
        let todayDate = new Date().getDate()
        let homeDate = tzMoment.moment(new Date()).format('D')
        if (todayDate != homeDate) {
            today = new Date(tzMoment.moment(new Date()).subtract(1, 'days'))
            //alert('non matching ' + today)
        }
        let d = today

        d.setHours(0, 0, 0, 0)

        if (date < d) {
            return {
                className: 'past',
                style: {
                    //backgroundColor: 'rgb(230,230,230)',
                },
            }
            //backgroundColor: '#eaf6ff'
        } else return {}

        /*
        else if (date.toString() === d.toString()) {
            return {
                className: 'today',
                style: {
                    backgroundColor: '#eaf6ff',
                    //backgroundColor: '#FCF7CF'
                },
            }
        } else return {}
        */
    }

    const customSlotPropGetter = (date) => {
        /*
        if (date.getDate() === 7 || date.getDate() === 15)
            return {
                className: 'special-day',
                style: {
                    border:
                        'solid 3px ' + (date.getDate() === 7 ? '#faa' : '#afa')
                }
            }
        else return {}
        */
        return {}
    }
    const eventPropGetter = (event) => {
        console.log('event prop getter ' + event.eventView)
        if (event.eventView !== 'agenda') {
            let obj = {}
            if (event.isNoShow) {
                obj.style = { backgroundColor: NOSHOW_BG_COLOR }
                obj.className = 'noshow'
            } else if (event.isDeclined) {
                obj.style = { backgroundColor: DECLINED_BG_COLOR }
                obj.className = 'declined'
            } else if (event.isCanceled) {
                obj.style = { backgroundColor: CANCELED_BG_COLOR }
                obj.className = 'canceled'
            } else if (event.isConfirmed) {
                obj.style = { backgroundColor: CONFIRMED_BG_COLOR }
                obj.className = 'confirmed'
            } else if (event.isPending) {
                obj.style = { backgroundColor: PENDING_BG_COLOR }
                obj.className = 'pending'
            } else {
                obj.style = { backgroundColor: AVAILABLE_BG_COLOR }
                obj.className = 'open'
            }
            return obj
        } else {
            return
        }
    }

    let accountActivationAlert
    if (!props.user.isActivated) {
        accountActivationAlert = (
            <Alert severity="success">
                Hi! We need to verify your application before you can request
                shifts. This process normally takes 24 - 48 hours. We will email
                you when your account has been activated. Please contact
                support@nurselab.co with any questions.
            </Alert>
        )
    } else {
        accountActivationAlert = ''
    }
    const renderCalendarViewButtons = (tProps) => {
        let viewNames = tProps.views
        const tView = tProps.view

        console.log('views')
        console.log(viewNames)
        if (viewNames.length > 1) {
            return viewNames.map((name) => (
                <button
                    type="button"
                    key={name}
                    className={{
                        'rbc-active': tView === name,
                    }}
                    onClick={() => tProps.onView(name)}
                >
                    {tProps.localizer.messages[name]}
                </button>
            ))
        }
    }

    const formats = React.useMemo(
        () => ({
            dateFormat: 'D',
        }),
        []
    )

    return (
        <Container
            style={{
                maxWidth: '100%',
                width: '100%',
                padding: 0,
                marginLeft: isDesktop ? '3em' : '0',
                marginRight: isDesktop ? '3em' : '0',
                marginTop: isDesktop ? '2em' : '1em',
                height: !isDesktop
                    ? `calc(100vh - 3.5em - ${props.bottomSafeArea}px - ${props.topSafeArea}px )`
                    : 'auto',
                overflowY: !isDesktop ? 'scroll' : 'auto',
            }}
        >
            {/*<h2>Calendar</h2>*/}
            {accountActivationAlert}
            <Calendar
                ref={calendarRef}
                localizer={localizer}
                formats={formats}
                events={events}
                onSelectEvent={onSelectEvent}
                views={
                    isDesktop
                        ? {
                              month: true,
                              // remove becuase does not support overlapping events well
                              //work_week: threeDayWeek,
                              agenda: true,
                          }
                        : {
                              month: true,
                              agenda: true,
                          }
                }
                step={30}
                eventPropGetter={eventPropGetter}
                slotPropGetter={customSlotPropGetter}
                components={{
                    toolbar: (tProps) => (
                        <div
                            className="rbc-toolbar"
                            style={!isDesktop ? { fontSize: '1.6em' } : null}
                        >
                            {/*
                            <span className="rbc-today-btn">
                                <IconButton
                                    component="span"
                                    onClick={() => {
                                        tProps.onNavigate('TODAY')
                                    }}
                                    size="large"
                                >
                                    <RestoreIcon />
                                </IconButton>
                            </span>
                            */}

                            <span
                                className="rbc-toolbar-label"
                                style={{
                                    width: !isDesktop ? '100%' : 'initial',
                                }}
                            >
                                <IconButton
                                    component="span"
                                    color="primary"
                                    onClick={() => {
                                        tProps.onNavigate('PREV')
                                    }}
                                    size="large"
                                    style={{
                                        float: !isDesktop ? 'left' : 'clear',
                                    }}
                                >
                                    {/* todo support ios icon 
                                    <ArrowBackIosIcon />
*/}
                                    <ArrowBackIcon />
                                </IconButton>
                                <span style={{ verticalAlign: 'middle' }}>
                                    {tProps.label}
                                </span>
                                <IconButton
                                    component="span"
                                    color="primary"
                                    onClick={() => {
                                        tProps.onNavigate('NEXT')
                                    }}
                                    size="large"
                                    style={{
                                        float: !isDesktop ? 'right' : 'clear',
                                    }}
                                >
                                    {/* todo support ios icon 
                                    <ArrowForwardIosIcon/>
*/}
                                    <ArrowForwardIcon />
                                </IconButton>
                            </span>

                            {/*
                            <span className="rbc-btn-group">
                                {renderCalendarViewButtons(tProps)}
                            </span>
                            */}
                        </div>
                    ),
                    /*timeGutterHeader: () => <p>Custom gutter text</p>,*/
                    /*
                        event: event => (
                            <div
                                style={{
                                    borderColor: 'green',
                                    backgroundColor: 'blue'
                                }}
                            >
                                test
                                {event.event.resourceId}
                                {event.event.startTime}
                                {JSON.stringify(event)}
                            </div>
                        )
                        */
                    /*
                        eventWrapper: eventWrapperProps => {
                            const style = {
                                border: '4px solid',
                                borderColor:
                                    eventWrapperProps.event.start.getHours() %
                                    2 ===
                                    0
                                        ? 'green'
                                        : 'red',
                                padding: '5px',
                                backgroundColor: 'red'
                            }
                            console.log('event wrapper')
                            console.log(eventWrapperProps.children)
                            return (
                                <div
                                    style={style}
                                    userProps={{ className: 'testing' }}
                                    className="test"
                                >
                                    {eventWrapperProps.children}
                                </div>
                            )
                        }
                        */
                }}
                messages={{
                    agenda: 'Summary',
                    event: 'Nurses',
                    noEventsInRange: 'No shifts scheduled in this time period.',
                    work_week: '+3 Days',
                }}
                dayPropGetter={customDayPropGetter}
                startAccessor="start"
                endAccessor="end"
                showMultiDayTimes={true}
                style={isDesktop ? { height: 800 } : { height: '80%' }}
                onView={(view) => switchView(view)}
                //resources={resourceMap}
                //resourceIdAccessor="resourceId"
                //resourceTitleAccessor="resourceTitle"
                dayLayoutAlgorithm="no-overlap"
                popup={true}
            />{' '}
            {/*
                    getDrilldownView={(
                        targetDate,
                        currentViewName,
                        configuredViewNames
                    ) => switchView(currentViewName)}
                    */}
            <br />
            <br />
            {lastView === 'agenda' ? (
                ''
            ) : (
                <div>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h4" component="h4">
                                <b>Calendar Legend</b>
                            </Typography>
                            <br />
                            {/*<Typography color="textSecondary">adjective</Typography>*/}
                            <Typography variant="body2" component="div">
                                <div>
                                    <div
                                        style={{
                                            backgroundColor: CONFIRMED_BG_COLOR,
                                            display: 'inline-block',
                                            marginRight: '1em',
                                            width: '8em',
                                            height: '2em',
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                    Confirmed Shift
                                </div>
                                <br />
                                <div>
                                    <div
                                        style={{
                                            backgroundColor: PENDING_BG_COLOR,
                                            display: 'inline-block',
                                            marginRight: '1em',
                                            width: '8em',
                                            height: '2em',
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                    Pending confirmation by facility
                                </div>
                                <br />
                                <div>
                                    <div
                                        style={{
                                            backgroundColor: AVAILABLE_BG_COLOR,
                                            display: 'inline-block',
                                            marginRight: '1em',
                                            width: '8em',
                                            height: '2em',
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                    Open shift available
                                </div>
                                <br />

                                <div>
                                    <div
                                        style={{
                                            backgroundColor: CANCELED_BG_COLOR,
                                            display: 'inline-block',
                                            marginRight: '1em',
                                            width: '8em',
                                            height: '2em',
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                    Canceled Shift
                                </div>
                                <br />
                                <div>
                                    <div
                                        style={{
                                            backgroundColor: NOSHOW_BG_COLOR,
                                            display: 'inline-block',
                                            marginRight: '1em',
                                            width: '8em',
                                            height: '2em',
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                    Shift no-show. If you need to cancel please
                                    let the facility know in advance.
                                </div>
                                <br />
                                <div>
                                    <div
                                        style={{
                                            backgroundColor: DECLINED_BG_COLOR,
                                            display: 'inline-block',
                                            marginRight: '1em',
                                            width: '8em',
                                            height: '2em',
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                    Declined Request. Shift was filled by
                                    another nurse.
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                    <br />
                    <br />
                    <br />
                </div>
            )}
            {/* nurse request */}
            <Dialog
                maxWidth={'sm'}
                fullWidth={true}
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={{ textAlign: 'center' }}>
                    <CardShift shiftCollection={selectedShift} width={12} />
                </div>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(MyCalendar)
