import React, { useRef, useState } from 'react'
import { connect } from 'react-redux' //Code

import axios from 'axios'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
//import { useDispatch } from 'react-redux'

import AuthService from '../../services/authService.js'
import CircularProgress from '@mui/material/CircularProgress'
import ShiftService from '../../services/shiftService.js'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import { Camera, CameraResultType } from '@capacitor/camera'
import { decode } from 'base64-arraybuffer'
import { v4 as uuidv4 } from 'uuid'
import utils from '../../helpers/utils'

import {
    openTimesheetDialog,
    closeTimesheetDialog,
    //toggleTimesheetUpload,
    resetTimesheetUploadToggle,
} from './timesheetSlice.js'

import { popNavStack, pushNavStack } from '../NavStack/navStackSlice'

const mime = require('mime-types')

const mapStateToProps = (state) => ({
    isOpen: state.timesheet.isOpen,
    shift: state.timesheet.shift,
    shiftId: state.timesheet.shiftId,
    shiftStatus: state.timesheet.shiftStatus,
    shiftCollection: state.timesheet.shiftCollection,
    shiftRequest: state.timesheet.shiftRequest,
    doUploadTimesheet: state.timesheet.doUploadTimesheet,
    navStack: state.navStack,
})

const mapDispatchToProps = (dispatch) => {
    return {
        openDialog: () => {
            dispatch(openTimesheetDialog())
        },

        closeDialog: () => {
            dispatch(closeTimesheetDialog())
        },

        resetTimesheetUploadToggle: () => {
            dispatch(resetTimesheetUploadToggle())
        },
        popNavStack: () => {
            dispatch(popNavStack())
        },
        pushNavStack: (item) => {
            dispatch(pushNavStack(item))
        },
    }
}

const Timesheet = (props) => {
    // handle back button + see timsehet slice  for implemntation
    React.useEffect(() => {
        const onPopChange = (e) => {
            if (!props.navStack.isUnlocked) {
                return
            }
            let last = props.navStack.stack.slice(-1)[0]

            if (last === 'timesheet' && props.isOpen) {
                props.popNavStack()
                props.closeDialog()

                utils.showTawk()
                window.removeEventListener('popstate', onPopChange)
                return
            }
        }
        window.addEventListener('popstate', onPopChange)
    }, [props.navStack.stack])

    //const shiftRequest = props.shiftRequest
    const shift = props.shift

    //const associate = shiftRequest.user

    //console.log(shift)
    //const shiftCollection = props.shiftCollection

    //const timesheetUpload = useRef(null)
    const [timesheetUrl, setTimesheetUrl] = useState('') // storing the uploaded file

    //const [isNoShowDialogOpen, setIsNoShowDialogOpen] = React.useState(false)
    // const [isClockDialogOpen, setIsClockDialogOpen] = React.useState(false)
    /*
    const handleViewTimesheetDialogClose = () => {
        setIsOpenViewTimesheetDialog(false)
    }
    */

    const handleOpenViewTimesheetDialog = async () => {
        //required for back key nav, save change to history
        props.pushNavStack('timesheet')

        utils.hideTawk()
        let headers = await AuthService.getHeader()

        let res
        // pull shiftid from shift obj or shift obj
        let shiftId = props.shiftId ? props.shiftId : shift.id
        //shiftId = shift.id

        try {
            res = await axios.get(
                `/api/nurse/file/timesheet/shift/${shiftId}`,
                {
                    headers: headers,
                }
            )
        } catch (e) {
            window.alert(
                'Sorry, there was an error. Please try again in a few minutes.'
            )
            return
        }

        let url = res.data.url
        setTimesheetUrl(url)
        return
    }

    const getImage = async () => {
        const image = await Camera.getPhoto({
            quality: 80,
            resultType: CameraResultType.Base64,
        })

        const blob = new Blob([new Uint8Array(decode(image.base64String))], {
            type: `image/${image.format}`,
        })

        handleTimesheetBlobUpload(blob)
    }

    const handleTimesheetBlobUpload = async (blob) => {
        if (props.isOpen && isLoading) {
            setIsLoading(true)
        } else {
            setIsLoadingAndModalClosed(true)
        }
        const file = blob
        console.log(file)

        let fileType = mime.extension(blob.type)

        const data = new FormData()
        let fileName = 'timesheet-' + uuidv4() + '.' + fileType
        console.log(fileName)
        data.append('image', blob, fileName)
        ///NOTE shift == shiftcollection and shiftcollection.shift = shift. confusing! todo fix
        /*
        let shiftId = shift.id
        console.log(shift)
        console.log(JSON.stringify(props.shift))
        console.log(JSON.stringify(shift))
            */
        // pull shiftid from shift obj or shift obj
        let shiftId = props.shiftId ? props.shiftId : shift.id
        data.append('shiftId', shiftId)

        for (let propertyName in shift) {
            data.append(propertyName, shift[propertyName])
        }

        let headers = await AuthService.getHeader()
        //let res = null
        try {
            await axios.post(
                `/api/nurse/file/timesheet/shift/${shiftId}`,
                //`/api/nurse/file/timesheet`,
                data,
                {
                    headers: headers,
                }
            )
        } catch (e) {
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }

        /*
        try {
            if (res) {
                await UserService.getAccount()
            }
        } catch (e) {
            console.log(e)
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }
        */

        if (props.isOpen) {
            handleCloseTimesheetDialog()
        }
        //reload upload/view timesheets
        await ShiftService.getAvailableShifts()
        if (props.isOpen && isLoading) {
            setIsLoading(false)
        } else {
            setIsLoadingAndModalClosed(false)
        }
    }

    React.useEffect(() => {
        if (!props.isOpen) {
            // clear prior timesheet image
            setTimesheetUrl('')
            return
        }
        handleOpenViewTimesheetDialog()
    }, [props.isOpen])

    React.useEffect(() => {
        if (!props.doUploadTimesheet) {
            // clear prior timesheet image
            return
        }
        uploadTimesheet()
        props.resetTimesheetUploadToggle()
    }, [props.doUploadTimesheet])
    const [isLoading, setIsLoading] = React.useState(false)
    const [isLoadingAndModalClosed, setIsLoadingAndModalClosed] =
        React.useState(false)

    const openTimesheetDialog = () => {
        props.openTimesheetDialog()
        /*
        props.openProfileDialog(
            props.shiftCollection,
            props.associateRequest,
            props.shift
        )
        */
    }

    const handleCloseTimesheetDialog = () => {
        if (props.isOpen) {
            window.history.back()
        } else {
            console.log('already closed')
        }
        //setIsClockDialogOpen(false)
        // props.closeDialog()
    }

    const uploadTimesheet = () => {
        //timesheetUpload.current.click()
        getImage()
    }

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xl'))

    return (
        <div>
            {/*
            <input
                type="file"
                accept="image/*;capture=camera"
                capture="camera"
                onChange={handleTimesheetUpload}
                hidden
                ref={timesheetUpload}
            />
            */}
            <Dialog
                fullScreen={fullScreen}
                open={props.isOpen}
                onClose={handleCloseTimesheetDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'xl'}
            >
                <div
                    style={{
                        height: props.topSafeArea + 'px',
                        width: '100%',
                        backgroundColor: '#F8F8F8',
                    }}
                ></div>
                <DialogTitle id="alert-dialog-title">
                    Viewing Timesheet
                </DialogTitle>
                <DialogContent style={{ display: 'flex' }}>
                    <img
                        src={timesheetUrl}
                        alt=""
                        style={{
                            objectFit: 'contain',
                            maxWidth: fullScreen ? '100%' : '100%',
                            maxHeight: fullScreen ? '100%' : '100%',
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseTimesheetDialog}
                        color="primary"
                    >
                        Close
                    </Button>
                    <Button
                        color="primary"
                        autoFocus
                        onClick={() => getImage()}
                    >
                        Replace Timesheet
                    </Button>
                    {isLoading ? <CircularProgress /> : null}
                </DialogActions>
                <div
                    style={{
                        height: props.bottomSafeArea + 'px',
                        width: '100%',
                        backgroundColor: 'white',
                    }}
                ></div>
            </Dialog>
            {/* only if timesheet is uploaded outside of modal */}
            {isLoadingAndModalClosed ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        left: 0,
                        height: '100vh',
                        width: '100vw',
                        zIndex: 10000000,
                        backgroundColor: 'rgba(0,0,0,0.3)',
                        position: 'fixed',
                        top: 0,
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size="8em" />
                </div>
            ) : (
                ''
            )}
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Timesheet)
