import React from 'react'
import { connect } from 'react-redux' //Code

import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'

import Typography from '@mui/material/Typography'
import axios from 'axios'

import Avatar from '@mui/material/Avatar'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'

import AuthService from '../../services/authService.js'
import ShiftService from '../../services/shiftService.js'
import CircularProgress from '@mui/material/CircularProgress'

import MailOutlineIcon from '@mui/icons-material/MailOutline'
//import CallIcon from '@mui/icons-material/Call'
//
import NoteIcon from '@mui/icons-material/Note'
import DescriptionIcon from '@mui/icons-material/Description'
//import InfoIcon from '@mui/icons-material/Info'
import WysiwygIcon from '@mui/icons-material/Wysiwyg'
//import ScheduleIcon from '@mui/icons-material/Schedule'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
//import TrafficIcon from '@mui/icons-material/Traffic'
//import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
//import BadgeIcon from '@mui/icons-material/Badge'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'

import utils from '../../helpers/utils'
import Tooltip from '@mui/material/Tooltip'
import { openModal, closeModal } from '../PlivoModal/plivoModalSlice'
import { openMailModal, closeMailModal } from '../MailModal/mailModalSlice'
import {
    openTimesheetDialog,
    toggleTimesheetUpload,
} from '../Timesheet/timesheetSlice'

import {
    peakBackNavStack,
    popNavStack,
    pushNavStack,
} from '../NavStack/navStackSlice'

import CancelShiftDialog from '../CancelShiftDialog/CancelShiftDialog'
import { openSnackbarMessage } from '../SnackbarMessage/snackbarMessageSlice'

import { useTheme } from '@mui/material/styles'
//reload upload/view timesheets

import useMediaQuery from '@mui/material/useMediaQuery'
const moment = require('moment')

const mapStateToProps = (state) => ({
    user: state.user,
    navStack: state.navStack,
})
const mapDispatchToProps = (dispatch) => {
    return {
        plivoCall: (shiftCollection) => {
            dispatch(openModal(shiftCollection))
        },
        openMailModal: (shiftCollection) => {
            dispatch(openMailModal(shiftCollection))
        },
        closeModal: () => {
            dispatch(closeModal())
        },
        uploadTimesheet: (shift) => {
            let data = {
                shift: shift,
            }
            console.log('upload timesheet  shift data ')
            console.log(shift)
            dispatch(toggleTimesheetUpload(data))
        },
        openTimesheetDialog: (shiftCollection, shiftRequest, shift) => {
            console.log('opening timesheet dialog')

            let data = {
                shift: shift,
                shiftRequest: shiftRequest,
                shiftCollection: shiftCollection,
            }
            dispatch(openTimesheetDialog(data))
        },
        peakBackNavStack: () => {
            dispatch(peakBackNavStack())
        },
        popNavStack: () => {
            dispatch(popNavStack())
        },
        pushNavStack: (item) => {
            dispatch(pushNavStack(item))
        },
        openSnackbarMessage: (data) => {
            dispatch(openSnackbarMessage(data))
        },
    }
}

/*
const getPayoutHourlyRate = (shiftCollection) => {
    let payoutHourlyRate = 0
    if (shiftCollection.shift && shiftCollection.shift.payoutHourlyRate) {
        payoutHourlyRate = shiftCollection.shift.payoutHourlyRate
        if (payoutHourlyRate.toString().indexOf('.') !== -1) {
            payoutHourlyRate = parseFloat(payoutHourlyRate).toFixed(2)
        }
        return payoutHourlyRate
    }
    let r1 = shiftCollection.payoutHourlyRate // default shift rate
    let r2 = 0 // one time shift request
    let r3 = 0

    if (
        shiftCollection.request &&
        shiftCollection.request.customPayoutHourlyRate
    ) {
        r2 = shiftCollection.request.customPayoutHourlyRate
    }
    if (shiftCollection.customAssociateFacilityPayRate) {
        if (shiftCollection.customAssociateFacilityPayRate.isConfirmed) {
            r3 = shiftCollection.customAssociateFacilityPayRate.payoutHourlyRate
        }
    }
    let tmp = [r1, r2, r3]
    tmp = tmp.map((val) => {
        if (val.toString().indexOf('.') !== -1) {
            val = parseFloat(val).toFixed(2)
        }
        return val
    })
    payoutHourlyRate = Math.max(...tmp)
    return payoutHourlyRate
}
*/

const getIsCrisisRate = (shiftCollection) => {
    if (shiftCollection.data && shiftCollection.data.zone === 'Red') {
        return true
    } else {
        return false
    }
}

const getPayoutHourlyRate = (shiftCollection) => {
    let phr = 0

    // if already confirmed
    let isConfirmedShift = false
    if (shiftCollection.shift && shiftCollection.shift.payoutHourlyRate) {
        isConfirmedShift = true
    }

    if (isConfirmedShift) {
        phr = shiftCollection.shift.payoutHourlyRate
        if (phr.toString().indexOf('.') !== -1) {
            phr = parseFloat(phr).toFixed(2)
        }
        return phr
    }

    // user requested special rate so give them that
    if (
        shiftCollection.request &&
        shiftCollection.request.customPayoutHourlyRate
    ) {
        phr = shiftCollection.request.customPayoutHourlyRate
        if (phr.toString().indexOf('.') !== -1) {
            phr = parseFloat(phr).toFixed(2)
        }
        return phr
    }
    let r1 = shiftCollection.payoutHourlyRate // default shift rate
    let r2 = 0 // custom crisis rate
    let r3 = 0 // custom base rate

    let isCrisisRate = getIsCrisisRate(shiftCollection)

    let cafp = shiftCollection.customAssociateFacilityPayRate
    if (isCrisisRate) {
        r1 = shiftCollection.crisisPayoutHourlyRate
        if (cafp && cafp.isCrisisPayoutHourlyRateConfirmed) {
            r2 = cafp.crisisPayoutHourlyRate ? cafp.crisisPayoutHourlyRate : 0
        }
    }

    if (cafp && cafp.isPayoutHourlyRateConfirmed) {
        r3 = cafp.payoutHourlyRate ? cafp.payoutHourlyRate : 0
    }

    console.log('testing payout hourly rate ', r1, r2, r3)
    let tmp = [r1, r2, r3]
    tmp = tmp.map((val) => {
        if (!val) {
            return 0
        }
        if (val && val.toString().indexOf('.') !== -1) {
            val = parseFloat(val).toFixed(2)
        }
        return val
    })
    phr = Math.max(...tmp)
    return phr
}

const CardShift = (props) => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
    /* popover */
    const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null)
    const [popoverText, setPopoverText] = React.useState('')
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)

    const [cancelDialogState, setCancelDialogState] = React.useState(false)
    const [cancelAction, setCancelAction] = React.useState('')
    /* end pop over */

    let shiftCollection = props.shiftCollection

    let isPastShift = false
    if (!moment(shiftCollection.end).isAfter(new Date())) {
        isPastShift = true
    }
    const [isOpenRequestShiftDialog, setIsOpenRequestShiftDialog] =
        React.useState(false)
    //const [isOpenCancelDialog, setIsOpenCancelDialog] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)

    //set hourly rate
    let payoutHourlyRate = getPayoutHourlyRate(shiftCollection)
    payoutHourlyRate = Number(payoutHourlyRate).toFixed(2)

    const handleCallFacility = (shiftCollection) => {
        props.plivoCall(shiftCollection)
    }
    const handleMessageFacility = (shiftCollection) => {
        props.openMailModal(shiftCollection)
    }

    // start open nurse shift request
    const handleOpenNurseShiftRequest = (shiftId) => {
        //required for back key nav, save change to history
        props.pushNavStack('shiftRequest')
        // end requirement

        setIsOpenRequestShiftDialog(true)
        return
    }
    // handle back button + see timsehet slice  for implemntation
    // limitation closes all cascading modals that are open
    React.useEffect(
        () => {
            const onPopChange = (e) => {
                if (!props.navStack.isUnlocked) {
                    return
                }
                //flushSync(() => console.log(cancelDialogState))
                let last = props.navStack.stack.slice(-1)[0]
                //console.log('shift request close | ' + last)
                if (last === 'shiftRequest' && isOpenRequestShiftDialog) {
                    props.popNavStack()
                    setIsOpenRequestShiftDialog(false)
                    console.log('cancel nurse shift request match')
                    window.removeEventListener('popstate', onPopChange)
                } else if (
                    last === 'cancelNurseShiftRequest' &&
                    //isOpenCancelDialog
                    cancelDialogState
                ) {
                    /*
                     */
                    //setIsOpenCancelDialog(false)
                    props.popNavStack()
                    //setIsOpenCancelDialog(false)
                    setCancelDialogState(false)
                    console.log('cancel nurse shift request match')
                    window.removeEventListener('popstate', onPopChange)
                    return
                }
            }
            window.addEventListener('popstate', onPopChange)
        },

        //[cancelDialogState, props.navStack])
        [props.navStack.stack, props.navStack.isUnlocked]
    )
    /*
    React.useEffect(() => {
        console.log('state change ' + isOpenCancelDialog)
    }, [isOpenCancelDialog])
    */

    const handleRequestShiftDialogClose = () => {
        //setIsOpenRequestShiftDialog(false)
        window.history.back()
    }
    // end close open nurse shift request

    const handleOpenCancelNurseShiftRequest = (shiftId, action) => {
        setCancelAction(action)

        props.pushNavStack('cancelNurseShiftRequest')
        setCancelDialogState(true)
        //setIsOpenCancelDialog(true)
    }

    const handleCloseCancelDialog = () => {
        //setIsOpenCancelDialog(false)
        window.history.back()
    }
    const handleOpenViewTimesheetDialog = async (shiftId) => {
        props.openTimesheetDialog(
            props.shiftCollection,
            props.associateRequest,
            props.shiftCollection.shift
        )
    }
    const handleConfirmCancelDialog = async () => {
        //nurse data
        setIsLoading(true)
        let headers = await AuthService.getHeader()
        headers['Content-Type'] = 'application/json'
        //get request
        let request = shiftCollection.request
        //get request
        /*
        let request = requests.reduce((prev, cur, index) => {
            return new Date(prev.updated) > new Date(cur.updated) ? prev : cur
        }) // returns 11
        */

        await axios.delete(`/api/nurse/shift/request/${request.id}`, {
            headers: headers,
            data: shiftCollection,
        })

        //setCancelDialogState(false)
        window.history.back()

        let msg = `You have canceled this shift request.`
        props.openSnackbarMessage({
            isOpen: true,
            message: msg,
            alertType: 'success',
        })
        await ShiftService.getAvailableShifts()
        setIsLoading(false)
    }
    const handleRequestShift = async () => {
        if (!props.user.isActivated) {
            alert(
                'Sorry, your NurseLab application needs to be approved first. You will receive an email shortly once your account is approved. If you need assistance in the meantime, please reach out to us at support@nurselab.co.'
            )
            handleRequestShiftDialogClose()
            console.log('account is not activated')
            return
        }
        if (!props.user.isEnabled) {
            alert(
                'Sorry, your account is disabled. Please contact us at support@nurselab.co for further assistance.'
            )
            handleRequestShiftDialogClose()
            console.log('account is disabled')
            return
        }
        setIsLoading(true)
        let headers = await AuthService.getHeader()
        //console.log(shiftId)
        let shiftData = Object.assign({}, shiftCollection)
        shiftData.action = 'request'
        console.log(headers)

        await axios.post(`/api/nurse/shift/request`, shiftData, {
            headers: headers,
        })
        //reload user data
        setIsLoading(false)
        //setIsOpenRequestShiftDialog(false)
        window.history.back()
        let msg = `Shift is requested! We will let the ${shiftCollection.facilityName} that you have requested this shift. You will be notified if the facility accepts you for this shift.`
        props.openSnackbarMessage({
            isOpen: true,
            message: msg,
            alertType: 'success',
        })
        await ShiftService.getAvailableShifts()
    }

    let cardStyle = {
        justifyContent: 'space-between',
        height: '100%',
        position: 'relative',
        borderColor: '#D3D3D3',
        borderStyle: 'solid',
        borderWidth: '1px',
    }

    let shiftStatus = ''
    let firstCardButton = ''
    let secondCardButton = ''
    let thirdCardButton = ''
    if (shiftCollection.isNoShow) {
        cardStyle.backgroundColor = '#ffbaba'
        shiftStatus = <span style={{ color: 'red' }}> No-Show/Canceled</span>
        firstCardButton = ''
    } else if (
        shiftCollection.isCanceled ||
        (shiftCollection.shift && shiftCollection.shift.isCanceled)
    ) {
        cardStyle.backgroundColor = '#cccccc'
        shiftStatus = <span style={{ color: 'black' }}> Canceled </span>
        firstCardButton = ''
    } else if (isPastShift) {
        cardStyle.backgroundColor = '#E1EABF'
        shiftStatus = <span style={{ color: 'green' }}> Completed </span>
        firstCardButton = ''

        /*
                    Upload Timesheet

                </Button>
        */
        /*
         */
        //TODO BETTER FIX AFTER CALL
        if (shiftCollection.shift && shiftCollection.shift.hasTimesheet) {
            firstCardButton = (
                <Tooltip title="View Timesheet">
                    <Button
                        variant="contained"
                        onClick={() => handleOpenViewTimesheetDialog()}
                    >
                        View Timesheet
                    </Button>
                </Tooltip>
            )
        } else {
            firstCardButton = (
                <Tooltip title="Upload Signed Timesheet">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            props.uploadTimesheet(shiftCollection.shift)
                        }}
                    >
                        Upload Timesheet
                    </Button>
                </Tooltip>
            )
        }

        if (
            !shiftCollection.isInternallyAdministered ||
            shiftCollection.isTestFacility
        ) {
            secondCardButton = (
                <Tooltip title="Message Facility">
                    <Button
                        onClick={() => handleMessageFacility(shiftCollection)}
                    >
                        <MailOutlineIcon style={{ marginRight: '0.25em' }} />{' '}
                        {'  '}
                        Message
                    </Button>
                </Tooltip>
            )
        }
    } else if (shiftCollection.isConfirmed) {
        //#98c06e
        cardStyle.backgroundColor = '#E1EABF'
        shiftStatus = <span style={{ color: 'green' }}> Confirmed </span>
        firstCardButton = (
            <Tooltip title="Cancel Shift">
                <Button
                    variant="outlined"
                    onClick={() =>
                        handleOpenCancelNurseShiftRequest(
                            shiftCollection.id,
                            'cancelShift'
                        )
                    }
                >
                    Cancel Shift
                </Button>
            </Tooltip>
        )

        //secondCardButton = ''
        secondCardButton = (
            <Tooltip title="Message Facility">
                <Button onClick={() => handleMessageFacility(shiftCollection)}>
                    <MailOutlineIcon style={{ marginRight: '0.25em' }} /> {'  '}
                    Message
                </Button>
            </Tooltip>
        )
        /*
        secondCardButton = (
            <Tooltip title="Call Facility">
                <Button onClick={() => handleCallFacility(shiftCollection)}>
                    <CallIcon style={{ marginRight: '0.25em' }} /> {'  '}
                    Facility
                </Button>
            </Tooltip>
        )
        */
        /*
        if (shift.shift.hasTimesheet) {
            thirdCardButton = (
                <Tooltip title="View Timesheet">
                    <Button onClick={() => handleOpenViewTimesheetDialog()}>
                        View Timesheet
                    </Button>
                </Tooltip>
            )
        } else {
            thirdCardButton = (
                <Tooltip title="Upload Signed Timesheet">
                    <Button onClick={() => timesheetUpload.current.click()}>
                        Upload Timesheet
                    </Button>
                </Tooltip>
            )
        }
        */
    } else if (shiftCollection.isFilled && !shiftCollection.shift) {
        cardStyle.backgroundColor = '#B2BEC3'
        shiftStatus = (
            <span style={{ color: '#2d3436' }}>
                {' '}
                Requested, But Filled by Another Nurse
            </span>
        )
    } else if (shiftCollection.isPending) {
        // #DFEBCA
        // #DCEEBD
        cardStyle.backgroundColor = '#FDFCCC'
        shiftStatus = (
            <span style={{ color: 'orange' }}> Pending Confirmation</span>
        )

        firstCardButton = (
            <Tooltip title="Cancel Shift Request">
                <Button
                    variant="outlined"
                    onClick={() =>
                        handleOpenCancelNurseShiftRequest(
                            shiftCollection.id,
                            'cancelShiftRequest'
                        )
                    }
                >
                    Cancel Shift Request
                </Button>
            </Tooltip>
        )
    } else {
        shiftStatus = 'Available'

        firstCardButton = (
            <Tooltip title="Request shift with facility">
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                        handleOpenNurseShiftRequest(shiftCollection.id)
                    }
                >
                    Request Shift
                </Button>
            </Tooltip>
        )
    }

    const handlePopoverOpen = (event, text) => {
        setPopoverText(text)
        setPopoverAnchorEl(event.currentTarget)
        setIsPopoverOpen(true)
    }
    const handlePopoverClose = () => {
        setPopoverAnchorEl(null)
        setIsPopoverOpen(false)
    }

    let otRate = payoutHourlyRate * 1.5
    otRate = (Math.round(otRate * 100) / 100).toFixed(2)

    let payTmp = (
        <div>
            ${payoutHourlyRate}
            /hr. Based on {shiftCollection.duration} hrs.
            <br />
            30 minute break not paid.
            <br />
            Time worked past 8 hrs compensated at ${otRate}
            /hr.
        </div>
    )
    let userTotal = shiftCollection.userTotal
    if (!Number.isInteger(userTotal)) {
        if (userTotal) {
            userTotal = userTotal.toFixed(2)
        } else {
            userTotal = 'Reload page or contact support'
        }
    }
    let payDetails = (
        <span
            onMouseEnter={(e) => handlePopoverOpen(e, payTmp)}
            onMouseLeave={handlePopoverClose}
        >
            ${userTotal}
            <span
                style={{
                    marginLeft: '0.25em',
                    color: 'blue',
                    fontSize: '0.5em',
                }}
            >
                Details
            </span>
        </span>
    )
    let description = null
    if (shiftCollection.description) {
        description = <span>📘 Description: {shiftCollection.description}</span>
        if (shiftCollection.description.length > 50) {
            let synopsis = shiftCollection.description.substring(0, 50) + '... '
            description = (
                <div style={{ marginTop: '0.6em' }}>
                    <DescriptionIcon
                        style={{
                            verticalAlign: 'text-top',
                            fontSize: '1.25em',
                            color: '#606060',
                            marginRight: '0.35em',
                        }}
                    />
                    Description: {synopsis}
                    <span
                        style={{ color: 'blue' }}
                        onMouseEnter={(e) =>
                            handlePopoverOpen(e, shiftCollection.description)
                        }
                        onMouseLeave={handlePopoverClose}
                    >
                        read more
                    </span>
                </div>
            )
        }
    }
    let note = null
    if (shiftCollection.note && shiftCollection.note.length > 0) {
        note = (
            <div style={{ marginTop: '0.6em' }}>
                <NoteIcon
                    style={{
                        verticalAlign: 'text-top',
                        fontSize: '1.25em',
                        color: '#606060',
                        marginRight: '0.35em',
                    }}
                />

                {shiftCollection.note}
            </div>
        )

        if (shiftCollection.note.length > 50) {
            let noteSynopsis = shiftCollection.note.substring(0, 50) + '... '
            note = (
                <span>
                    <NoteIcon
                        style={{
                            verticalAlign: 'text-top',
                            fontSize: '1.25em',
                            color: '#606060',
                            marginRight: '0.35em',
                        }}
                    />

                    {noteSynopsis}
                    <span
                        style={{ color: 'blue' }}
                        onMouseEnter={(e) =>
                            handlePopoverOpen(e, shiftCollection.note)
                        }
                        onMouseLeave={handlePopoverClose}
                    >
                        read more
                    </span>
                </span>
            )
        }
    }
    /*
    let facilityLogo = shiftCollection.logoUrl ? (
        <CardMedia
            component="img"
            height="80"
            style={{
                objectFit: 'contain',
                paddingTop: '10px',
            }}
            image={shiftCollection.logoUrl}
            title={shiftCollection.facilityName}
        />
    ) : (
        <div
            style={{
                objectFit: 'contain',
                fontWeight: 'bolder',
                fontSize: '1.5em',
                textAlign: 'center',
                minHeight: '80px',
                paddingTop: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {shiftCollection.facilityName}
        </div>
    )
    */

    let facilityColor = utils.pastelColor(shiftCollection.facilityName)

    let bg = `rgb(${facilityColor.red}, ${facilityColor.green}, ${facilityColor.blue})`

    let facilityLogoSmall = shiftCollection.logoUrl ? (
        <img
            component="img"
            style={{
                objectFit: 'contain',
                display: 'inline-block',
                maxWidth: '70px',
                height: '43px',
                marginRight: '0.75em',
                left: '0',
            }}
            src={shiftCollection.logoUrl}
            alt={shiftCollection.facilityName}
        />
    ) : (
        <Avatar
            variant="rounded"
            style={{
                fontWeight: 'bolder',
                height: '43px',
                marginRight: '0.75em',
            }}
            sx={{ bgcolor: bg }}
        >
            {shiftCollection.facilityName.charAt(0)}
        </Avatar>
    )

    let gridItemSize = 3
    if (props.width ? (gridItemSize = props.width) : (gridItemSize = 3))
        return (
            <Grid item xs={12} sm={12} md={6} lg={4} xl={gridItemSize}>
                <Card
                    className={shiftCollection.isPending ? 'pending' : ''}
                    style={cardStyle}
                    elevation={1}
                >
                    <div
                        style={
                            !isDesktop
                                ? {
                                      margin: 'auto',
                                      textAlign: 'left',
                                  }
                                : {}
                        }
                    >
                        <Typography
                            variant="h6"
                            component="div"
                            style={{
                                textAlign: 'left',
                                width: '100%',
                                marginTop: '0.2em',
                                marginLeft: '0.5em',
                                fontSize: '1em',
                            }}
                        >
                            {isDesktop ? (
                                <span>
                                    {shiftStatus}
                                    <span
                                        style={{
                                            marginRight: '1em',
                                            float: 'right',
                                        }}
                                    >
                                        {shiftCollection.dateSmall}
                                    </span>
                                </span>
                            ) : (
                                <span>
                                    {shiftStatus}
                                    <span
                                        style={{
                                            marginRight: '1em',
                                            float: 'right',
                                        }}
                                    >
                                        {shiftCollection.dateSmall}
                                    </span>
                                </span>
                            )}
                        </Typography>
                        <hr
                            style={{
                                borderColor: '#E8E8E8',
                                marginTop: '0.25em',
                                marginBottom: '0.25em',
                            }}
                        />

                        <div
                            style={{
                                objectFit: 'contain',
                                fontWeight: 'bolder',
                                fontSize: '1.3em',
                                textAlign: 'left',
                                minHeight: isDesktop ? '3em' : '2em',
                                paddingTop: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start',
                                marginRight: '1em',
                                marginLeft: '0.5em',
                            }}
                        >
                            {facilityLogoSmall}
                            {shiftCollection.facilityName}
                        </div>
                        <CardContent>
                            <Typography
                                variant="body2"
                                component="p"
                                style={{}}
                            >
                                <AccessTimeIcon
                                    style={{
                                        verticalAlign: 'text-top',
                                        fontSize: '1.25em',
                                        color: '#606060',
                                        marginRight: '0.35em',
                                    }}
                                />{' '}
                                {'  '}
                                {shiftCollection.startTime} -{' '}
                                {shiftCollection.endTime}
                            </Typography>
                            <Typography
                                variant="body2"
                                component="p"
                                style={{
                                    marginTop: isDesktop ? '0.5em' : '0.3em',
                                }}
                            >
                                <WysiwygIcon
                                    style={{
                                        verticalAlign: 'text-top',
                                        fontSize: '1.25em',
                                        color: '#606060',
                                        marginRight: '0.35em',
                                    }}
                                />{' '}
                                <span style={{ paddingRight: '0.45em' }}>
                                    {payDetails}
                                </span>
                                <span
                                    style={{ paddingRight: '0.15em' }}
                                    aria-label="position"
                                ></span>
                                {shiftCollection.type}
                                {shiftCollection.data &&
                                shiftCollection.data.zone ? (
                                    <span
                                        style={{ paddingLeft: '0.75em' }}
                                        aria-label="position"
                                    >
                                        {shiftCollection.data.zone} zone
                                    </span>
                                ) : null}
                            </Typography>

                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                style={{
                                    marginTop: isDesktop ? '0.5em' : '0.3em',
                                }}
                            >
                                {description}
                            </Typography>

                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                style={{
                                    marginTop: isDesktop ? '0.5em' : '0.3em',
                                }}
                            >
                                {note}
                            </Typography>
                            <Typography
                                variant="body2"
                                component="p"
                                style={{
                                    marginTop: isDesktop ? '0.5em' : '0.3em',
                                }}
                            >
                                <CorporateFareIcon
                                    style={{
                                        verticalAlign: 'text-top',
                                        fontSize: '1.25em',
                                        marginRight: '0.35em',
                                        color: '#606060',
                                    }}
                                />
                                {'   '}
                                <span>
                                    {shiftCollection.address},{' '}
                                    {shiftCollection.city},{' '}
                                    {shiftCollection.zipcode}
                                </span>
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {firstCardButton}
                            {/*style={{ marginLeft: '3em' }}*/}
                            {secondCardButton}
                            {thirdCardButton}
                            {isLoading ? <CircularProgress /> : null}
                        </CardActions>
                    </div>

                    <Popover
                        id="mouse-over-popover"
                        open={isPopoverOpen}
                        anchorEl={popoverAnchorEl}
                        style={{
                            pointerEvents: 'none',
                            maxWidth: '500px',
                            minWidth: '70%',
                        }}
                        disableRestoreFocus
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Typography style={{ padding: '1em' }}>
                            {popoverText}
                        </Typography>
                    </Popover>
                </Card>
                <Dialog
                    key="shiftInterestModal"
                    open={isOpenRequestShiftDialog}
                    onClose={handleRequestShiftDialogClose}
                    aria-labelledby="Shift Request Modal"
                    aria-describedby="Request Shift"
                >
                    <DialogTitle id="Shift Request">
                        Interested in shift?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="Request Shift">
                            This shift is from{' '}
                            <b>
                                {shiftCollection.startTime} to{' '}
                                {shiftCollection.endTime}
                            </b>{' '}
                            on <b>{shiftCollection.date}</b> at{' '}
                            <b>{shiftCollection.facilityName}</b> located on{' '}
                            <b>
                                {shiftCollection.address},{' '}
                                {shiftCollection.city},{' '}
                                {shiftCollection.zipcode}
                            </b>
                            .<br />
                            The hourly rate for this shift is $
                            {payoutHourlyRate}.<br /> <br />
                            Would you like to request this shift?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleRequestShiftDialogClose}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleRequestShift}
                            color="primary"
                            autoFocus
                        >
                            Yes, Request Shift
                        </Button>
                        {isLoading ? <CircularProgress /> : null}
                    </DialogActions>
                </Dialog>
                {/*
                <Dialog
                    key={shiftCollection.id + 'cancelDialog'}
                    open={isOpenCancelDialog}
                    onClose={handleCloseCancelDialog}
                    aria-labelledby="cancelDialogLabel"
                    aria-describedby="cancelDialog"
                >
                    <DialogTitle id="cancel-dialog-title">
                        {cancelDialogTitle}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {cancelDialogText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCloseCancelDialog}
                            color="primary"
                        >
                            No
                        </Button>
                        <Button
                            onClick={handleConfirmCancelDialog}
                            color="primary"
                        >
                            Yes
                        </Button>
                        {isLoading ? <CircularProgress /> : null}
                    </DialogActions>
                </Dialog>
                */}
                <CancelShiftDialog
                    isOpen={cancelDialogState}
                    cancelAction={cancelAction}
                    shiftCollection={shiftCollection}
                    handleClose={handleCloseCancelDialog}
                />
            </Grid>
        )
}

export default connect(mapStateToProps, mapDispatchToProps)(CardShift)
