// client nurse platform
import React, { useEffect } from 'react'

import axios from 'axios'
import '@aws-amplify/ui-react/styles.css'
import {
    Heading,
    Authenticator,
    useTheme,
    useAuthenticator,
    CheckboxField,
    View,
    Image
} from '@aws-amplify/ui-react'

// remove?
import { I18n } from 'aws-amplify'
//import { Translations } from '@aws-amplify/ui-components'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css'
import NavBar from './NavBar.js'
import BottomNav from './features/BottomNav/BottomNav.js'
import MyCalendar from './pages/Calendar'
import Settings from './pages/Settings'
import Menu from './pages/Menu'
import Onboarding from './pages/Onboarding'
import Onboarding2 from './pages/Onboarding2'
//import Payments from './pages/Payments'
import History from './pages/History'
import Inbox from './pages/Inbox'

import SignupThankYou from './pages/SignupThankYou'
//import PlivoModal from './features/PlivoModal/PlivoModal'
import MailModal from './features/MailModal/MailModal'
import File from './features/File/File'
import Timesheet from './features/Timesheet/Timesheet'
import SnackbarMessage from './features/SnackbarMessage/SnackbarMessage'

import Home from './pages/Home'
import Utils from './helpers/utils'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Provider, ErrorBoundary, RollbarContext } from '@rollbar/react' // <-- Provider imports 'rollbar' for us

import AnalyticsPageService from './services/analyticsPageService.js'
//import { hotjar } from 'react-hotjar'

import { App as CapacitorApp } from '@capacitor/app'

import { Capacitor } from '@capacitor/core'

import useMediaQuery from '@mui/material/useMediaQuery'

import { unlockNavStack } from './features/NavStack/navStackSlice'
import {
    ThemeProvider,
    StyledEngineProvider,
    createTheme,
    adaptV4Theme
} from '@mui/material/styles'

import { SafeArea } from 'capacitor-plugin-safe-area'

//import { green, purple } from '@mui/material/colors'

//const theme = createTheme()

const theme = createTheme(
    adaptV4Theme({
        palette: {
            primary: {
                //main: purple[500],
                main: '#3f51b5'
            },
            secondary: {
                main: '#008080'
            }
        }
    })
)

// platform has to run on native otherwise, localhost port 7000 won't work
axios.defaults.baseURL = Capacitor.isNativePlatform()
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_API_URL

//axios.defaults.baseURL = 'http://localhost:7000'
//axios.defaults.baseURL = 'https://app-api.nurselab.co'

const mapStateToProps = state => ({ user: state.user })
const mapDispatchToProps = dispatch => {
    return {
        unlockNavStack: () => {
            dispatch(unlockNavStack())
        }
    }
}

const nodeEnv = process.env.NODE_ENV
/*
if (nodeEnv === 'production') {
    hotjar.initialize('2363543')
}
*/
// same configuration you would create for the Rollbar.js SDK
const rollbarConfig = {
    enabled: nodeEnv === 'production',
    accessToken: '7eeec1e1325648df819ce79bbeb989fc',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: 'production'
    }
}

/*
const msg = 'Please check your email for verification code'

I18n.setLanguage('en')
I18n.putVocabulariesForLanguage('en', {
    [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: msg,
})
*/
I18n.putVocabulariesForLanguage('en', {
    'Create Account': 'JOIN NOW' // Tab header
})

// set up back button behavior, only for android
CapacitorApp.addListener('backButton', ({ canGoBack }) => {
    if (!canGoBack) {
        CapacitorApp.exitApp()
    } else {
        window.history.back()
    }
})

const App = props => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

    const [bottomSafeArea, setBottomSafeArea] = React.useState(0)
    const [topSafeArea, setTopSafeArea] = React.useState(0)

    useEffect(
        () => {
            SafeArea.getSafeAreaInsets().then(({ insets }) => {
                // only calculate safe areas for ios. not needed for android 10...
                if (Capacitor.getPlatform() !== 'ios') {
                    return
                }
                setBottomSafeArea(insets.bottom)
                setTopSafeArea(insets.top)
                console.log('insets ' + insets)
            })
        },
        [SafeArea]
    )

    // amplify user data
    // code to run on component mount
    useEffect(
        () => {
            // only load tawk on desktop...
            if (!isDesktop) {
                return
            }
            /*!--Start of Tawk.to Script-->*/

            if (!window.Tawk_API) {
                window.Tawk_API = {}

                window.Tawk_API.customStyle = {
                    visibility: {
                        mobile: {
                            yOffset: '75px'
                        }
                    }
                }
            }

            ;(function() {
                var s1 = document.createElement('script'),
                    s0 = document.getElementsByTagName('script')[0]
                s1.async = true
                s1.src =
                    'https://embed.tawk.to/60452c9d1c1c2a130d65f4ee/1f073cibv'
                s1.charset = 'UTF-8'
                s1.setAttribute('crossorigin', '*')
                s0.parentNode.insertBefore(s1, s0)
            })()

            window.Tawk_API.onLoad = function() {
                window.Tawk_API.hideWidget()
            }

            Utils.loadChatUser(props.user)
            /*  <!--End of Tawk.to Script-->*/

            /*
        window.addEventListener('popstate', function () {
            console.log('test')
            props.unlockNavStack()
        })
        */
        },
        [isDesktop]
    )
    const authenticatorFormFields = {
        signUp: {
            given_name: {
                placeholder: 'First Name',
                isRequired: true,
                label: 'First Name:',
                labelHidden: true,
                order: 1
            },
            family_name: {
                placeholder: 'Last Name',
                isRequired: true,
                labelHidden: true,
                label: 'Last Name:',
                order: 2
            },
            password: {
                placeholder: 'Password',
                isRequired: true,
                labelHidden: true,
                label: 'Password:'
            },
            confirm_password: {
                placeholder: 'Confirm Password',
                isRequired: true,
                labelHidden: true,
                label: 'Confirm Password:'
            },
            email: {
                placeholder: 'Email',
                isRequired: true,
                labelHidden: true,
                label: 'Email:'
            },
            phone_number: {
                placeholder: 'Phone Number',
                labelHidden: true,
                isRequired: true
            }
        },
        signIn: {
            username: {
                placeholder: 'Email',
                isRequired: true,
                labelHidden: true,
                label: 'Email:'
            },
            password: {
                placeholder: 'Password',
                isRequired: true,
                labelHidden: true,
                label: 'Password:'
            }
        }
    }
    const authenticatorComponents = {
        Header() {
            const { tokens } = useTheme()

            if (isDesktop) {
                return (
                    <View
                        textAlign="left"
                        padding={`${tokens.space.large}  0  ${
                            tokens.space.medium
                        } 0`}
                        style={{ minWidth: '95vw' }}
                    >
                        <Image
                            alt="NurseLab logo"
                            src="/nurselab.png"
                            style={{ height: '5.5em', marginBottom: '0.5em' }}
                        />
                        <div
                            padding={`0 0  0 0`}
                            style={{ fontSize: '1.25em' }}
                        >
                            Welcome
                        </div>
                    </View>
                )
            } else {
                return (
                    <>
                        <View
                            textAlign="left"
                            padding={`${tokens.space.small}  0  ${
                                tokens.space.small
                            } 0`}
                            style={{ minWidth: '95vw' }}
                        >
                            <Image
                                alt="NurseLab logo"
                                src="/nurselab.png"
                                style={{
                                    height: '2.5em',
                                    marginBottom: '0.25em'
                                }}
                            />
                            <div
                                padding={`0 0  0 0`}
                                style={{ fontSize: '1.25em' }}
                            >
                                Welcome
                            </div>
                        </View>
                    </>
                )
            }
        },
        Footer() {
            // Return today's date and time
            let currentTime = new Date()
            let year = currentTime.getFullYear()

            return (
                <div
                    style={{
                        marginTop: '2em',
                        fontSize: '0.6em',
                        marginLeft: '0',
                        marginBottom: '1em'
                    }}
                >
                    All Rights Reserved, NurseCall Inc. DBA NurseLab ©️
                    {year}.
                    <div
                        style={{
                            height:
                                // for scroll
                                Capacitor.getPlatform() === 'ios' ? '60vh' : '0'
                        }}
                    />
                </div>
            )
        },

        SignIn: {
            Header() {
                const { tokens } = useTheme()

                /*
                    <Heading
                        padding={`${tokens.space.large} 0 0 ${tokens.space.large}`}
                        level={6}
                    >
                        Don't have an account?{' '}
                        <button onClick={toSignup}>Join.</button>
                    </Heading>
                    */
                return <></>
            }
        },
        SignUp: {
            Header() {
                const { tokens } = useTheme()

                if (isDesktop) {
                    return (
                        <Heading
                            padding={`${tokens.space.medium} 0 0 ${
                                tokens.space.medium
                            }`}
                            level={6}
                        >
                            Join Now To Get Shifts and Get Paid.
                        </Heading>
                    )
                } else {
                    return <></>
                }
            },
            FormFields() {
                const { validationErrors } = useAuthenticator()

                return (
                    <>
                        {/* Re-use default `Authenticator.SignUp.FormFields` */}
                        <Authenticator.SignUp.FormFields />
                        {/* Append & require Terms & Conditions field to sign up  */}
                        {/*hasError={!!validationErrors.acknowledgement}*/}
                        <CheckboxField
                            errorMessage={validationErrors.acknowledgement}
                            name="acknowledgement"
                            value="yes"
                            required={true}
                            label={
                                <span
                                    style={{
                                        display: 'inline-block',
                                        marginLeft: '0.35em',
                                        fontSize: '0.75em'
                                    }}
                                >
                                    By signing up, I agree with the{' '}
                                    <a
                                        href="https://nurselab.co/terms"
                                        target="_blank"
                                        style={{ display: 'inline-block' }}
                                    >
                                        terms and conditions
                                    </a>{' '}
                                    and{' '}
                                    <a
                                        href="https://nurselab.co/privacy"
                                        target="_blank"
                                        style={{ display: 'inline-block' }}
                                    >
                                        privacy policy
                                    </a>
                                    .
                                </span>
                            }
                        />
                    </>
                )
            }
        }
    }

    //const theme = useTheme()
    return (
        <Provider config={rollbarConfig}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <ErrorBoundary>
                        <div
                            className="App"
                            style={{ paddingTop: topSafeArea + 'px' }}
                        >
                            <div
                                style={{
                                    position: 'fixed',
                                    backgroundColor: 'white',
                                    width: '100%',
                                    zIndex: 2,
                                    top: 0,
                                    height: topSafeArea + 'px'
                                }}
                            />
                            <Authenticator
                                style={{
                                    // ios functionality
                                    overflowY: !isDesktop ? 'scroll' : 'auto',
                                    overflowX: !isDesktop ? 'hidden' : 'auto',
                                    height: !isDesktop
                                        ? `calc(100vh - ${topSafeArea}px - ${bottomSafeArea}px)`
                                        : 'auto'
                                }}
                                loginMechanisms={['email']}
                                className="amplifySignIn"
                                usernameAlias="email"
                                components={authenticatorComponents}
                                formFields={authenticatorFormFields}
                                signUpAttributes={[
                                    'given_name',
                                    'family_name',
                                    'phone_number'
                                ]}
                                services={{
                                    async validateCustomSignUp(formData) {
                                        if (!formData.acknowledgement) {
                                            return {}
                                        }
                                    }
                                }}
                            >
                                <Router>
                                    {/* main view port for content on desktop/mobile */}
                                    <div
                                        style={
                                            isDesktop
                                                ? {
                                                      display: 'flex',
                                                      marginTop: '0em',
                                                      padding: '0',
                                                      paddingTop: '0'
                                                  }
                                                : {
                                                      display: 'flex',
                                                      marginTop: '0em',
                                                      padding: '0',
                                                      paddingTop: '0'
                                                  }
                                        }
                                    >
                                        <NavBar
                                            topSafeArea={topSafeArea + 'px'}
                                        />
                                        {/*
              A <Switch> looks through all its children <Route>
              elements and renders the first one whose path
              matches the current URL. Use a <Switch> any time
              you have multiple routes, but you want only one
              of them to render at a time
            */}
                                        <Switch>
                                            <Route exact path="/">
                                                <RollbarContext context="/">
                                                    <Home
                                                        bottomSafeArea={
                                                            bottomSafeArea
                                                        }
                                                        topSafeArea={
                                                            topSafeArea
                                                        }
                                                    />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/onboarding">
                                                <RollbarContext context="onboarding">
                                                    <Onboarding
                                                        bottomSafeArea={
                                                            bottomSafeArea
                                                        }
                                                        topSafeArea={
                                                            topSafeArea
                                                        }
                                                    />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/onboarding2">
                                                <RollbarContext context="onboarding2">
                                                    <Onboarding2 />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/calendar">
                                                <RollbarContext context="calendar">
                                                    <MyCalendar
                                                        bottomSafeArea={
                                                            bottomSafeArea
                                                        }
                                                        topSafeArea={
                                                            topSafeArea
                                                        }
                                                    />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/history">
                                                <RollbarContext context="history">
                                                    <History
                                                        bottomSafeArea={
                                                            bottomSafeArea
                                                        }
                                                        topSafeArea={
                                                            topSafeArea
                                                        }
                                                    />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/inbox">
                                                <RollbarContext context="inbox">
                                                    <Inbox
                                                        bottomSafeArea={
                                                            bottomSafeArea
                                                        }
                                                        topSafeArea={
                                                            topSafeArea
                                                        }
                                                    />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/settings">
                                                <RollbarContext context="settings">
                                                    <Settings
                                                        bottomSafeArea={
                                                            bottomSafeArea
                                                        }
                                                        topSafeArea={
                                                            topSafeArea
                                                        }
                                                    />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/menu">
                                                <RollbarContext context="menu">
                                                    <Menu
                                                        topSafeArea={
                                                            topSafeArea
                                                        }
                                                    />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/sign-up-thank-you">
                                                <RollbarContext context="sign-up-thank-you">
                                                    <SignupThankYou />
                                                </RollbarContext>
                                            </Route>
                                        </Switch>
                                        {/*
                                        <PlivoModal />
                                        */}
                                        <File
                                            bottomSafeArea={bottomSafeArea}
                                            topSafeArea={topSafeArea}
                                        />
                                        <Timesheet
                                            bottomSafeArea={bottomSafeArea}
                                            topSafeArea={topSafeArea}
                                        />
                                        <MailModal />
                                        <SnackbarMessage />
                                    </div>
                                    <BottomNav
                                        bottomSafeArea={bottomSafeArea}
                                        topSafeArea={topSafeArea}
                                    />
                                    <AnalyticsPageService />
                                    {/*spaces ok below pages, not ok after amplify sign
                                in pages*/}
                                </Router>
                            </Authenticator>
                        </div>
                    </ErrorBoundary>
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    )
}

/*
export default withAuthenticator(App, {
    usernameAttributes: 'email'
/)

*/

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(App)
