import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'

//https://stackoverflow.com/questions/34836500/how-to-set-up-google-analytics-for-react-router
//
const AnalyticsPageService = props => {
    const location = useLocation()
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if (!window.location.href.includes('localhost')) {
            ReactGA.initialize('UA-110093977-3')
        }
        setInitialized(true)
    }, [])

    useEffect(
        () => {
            if (initialized) {
                ReactGA.pageview(location.pathname + location.search)
            }
        },
        [location]
    )
    return null
}

export default AnalyticsPageService
