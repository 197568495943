import Body from './Body.js'

import { stringToColor } from './color.js'
import { getDateTime, formatTime } from 'react-chat-engine-advanced'

let styles = require('./messageStyles.json')

const Message = (props) => {
    const senderUsername = props.sending
        ? props.username
        : props.message.sender_username
    const isTurnEnd =
        !props.nextMessage ||
        props.nextMessage.sender_username !== senderUsername

    var isNewTurn =
        !props.lastMessage ||
        props.lastMessage.sender_username !== senderUsername
    if (props.sending && !props.lastMessage) {
        isNewTurn =
            !props.chat.last_message.sender ||
            props.chat.last_message.sender_username !== senderUsername
    }

    const messageDateTime = getDateTime(props.message.created, props.offset)
    const messageTime = messageDateTime ? formatTime(messageDateTime) : ''

    let senderName = ''
    if (props.message.sender) {
        senderName =
            props.message.sender.first_name +
            ' ' +
            props.message.sender.last_name
    } else {
        senderName = props.firstName + ' ' + props.lastName
    }
    return (
        <div
            style={{
                display: 'inline-block',
                width: '100%',
                minHeight: isNewTurn && isTurnEnd && '54px',
                padding: '4px 0px',
            }}
        >
            {isNewTurn && (
                <div style={{ height: '0px', marginTop: 0 }}>
                    <div
                        style={{
                            ...styles.avatar,
                            ...{
                                backgroundColor: stringToColor(senderUsername),
                            },
                        }}
                    >
                        <div style={styles.avatarText}>
                            {senderName.substr(0, 2).toUpperCase()}
                        </div>
                    </div>
                </div>
            )}

            <div
                style={{
                    ...styles.messageRow,
                    ...{ color: props.sending ? '#959595' : 'black' },
                }}
            >
                {isNewTurn && (
                    <div style={styles.messageSender}>
                        {senderName} - {messageTime}
                    </div>
                )}
                <Body text={props.message.text} />
            </div>
        </div>
    )
}

export default Message
