import axios from 'axios'
import store from '../app/store'
import { setUser } from '../features/User/userSlice.js'
import { setShiftCollections } from '../features/ShiftCollection/shiftCollectionSlice.js'
import AuthService from './authService.js'
import TzMoment from '../plugins/TzMoment'

const UserService = {
    getAvailableShifts: async function (accessToken) {
        let headers = ''
        if (!accessToken) {
            headers = await AuthService.getHeader()
        } else {
            headers = { Authorization: 'Bearer ' + accessToken }
        }
        let shiftCollections = null
        let res = await axios.get('/api/nurse/shift/', {
            headers: headers,
        })
        if (res) {
            shiftCollections = res.data
        }
        shiftCollections = shiftCollections.map((x) => {
            let y = Object.assign({}, x)
            let shiftRange = y.dateTimeRange
            let start = shiftRange[0]
            let end = shiftRange[1]

            let tzMoment = new TzMoment(y.timezone)
            y.date = tzMoment.moment(start).format('MMMM Do, dddd')
            y.dateSmall = tzMoment.moment(start).format('MMM Do, dddd')
            y.startTime = tzMoment.moment(start).format('h:mma')
            y.endTime = tzMoment.moment(end).format('h:mma')
            y.isConfirmed = false
            y.isNoShow = false
            y.isPending = false
            y.isDeclined = false
            if (y.isCanceled || (y.shift && y.shift.isCanceled)) {
                y.isCanceled = true
            } else if (y.shift && y.shift.isNoShow) {
                y.isNoShow = true
            } else if (y.isFilled && !y.shift) {
                y.isDeclined = true
            } else if (y.shift && y.shift.id && y.shift.isCanceled !== true) {
                y.isConfirmed = true
                //y.status = 'Pending'
            } else if (
                y.request &&
                y.request.id &&
                y.request.isCanceled !== true
            ) {
                y.isPending = true
                //y.status = 'Available'
            }
            y.compensation =
                y.payoutHourlyRate *
                tzMoment.moment
                    .duration(tzMoment.moment(end).diff(tzMoment.moment(start)))
                    .asHours()

            return y
        })
        shiftCollections.sort(
            (a, b) =>
                new Date(a.dateTimeRange[0]) - new Date(b.dateTimeRange[1])
        )
        console.log('test save ')
        store.dispatch(setShiftCollections(shiftCollections))
        return shiftCollections
    },
    getAccount: async function (accessToken) {
        let headers = ''
        if (!accessToken) {
            headers = await AuthService.getHeader()
        } else {
            headers = { Authorization: 'Bearer ' + accessToken }
        }

        let user = null
        let res = await axios.get('/api/nurse/account/', {
            headers: headers,
        })
        if (res) {
            user = res.data
        }

        /*
        let facilities = user.facilities

        // set facility if facility is not selected, update facility if exists
        // (1) replace user facility w/ refreshed facility obj
        let selectedFacilityId = null
        if (store.selectedFacility && store.selectedFacility.id) {
            selectedFacilityId = store.selectedFacility.id
            let updatedFacility = facilities.find(
                o => o.id === selectedFacilityId
            )
            if (updatedFacility) {
                user.selectedFacility = updatedFacility
            }
        } else {
            // (2) TODO make smarter; ok for now bc we don't support multi-facility
            for (let x = 0; x < facilities.length; x++) {
                let facility = facilities[x]
                user.selectedFacility = facility
            }
        }
        //console.log('user service ' + user.selectedFacility.shifts.length)
        */

        store.dispatch(setUser(user))
        return user
    },
}

export default UserService
