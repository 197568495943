/*
 *
 * NOTES
 * AFTER JOTFORM FILLED, REDIRECTS OCCUR IN JOTFORM FIELD SETTINGS
 * */

import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

import { compose } from 'redux' //Code
import { connect } from 'react-redux' //Code

/*
import {
    InAppBrowser,
    InAppBrowserOptions,
} from '@awesome-cordova-plugins/in-app-browser'
*/
import { Capacitor } from '@capacitor/core'
//import { App as CapacitorApp } from '@capacitor/app'

//import ShiftService from '../services/shiftService.js'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { PostScribe } from 'react-postscribe'
import { ErrorBoundary, LEVEL_DEBUG } from '@rollbar/react'
import Utils from '../helpers/utils'

import {
    hideBottomNav,
    showBottomNav,
} from '../features/BottomNav/bottomNavSlice'

//import postscribe from 'postscribe'

/*rnventory
const NEW_USER_FORM = '211966320364151'
const EXISTING_USER_FORM = '223075107357150'
*/
// TESTING nurselab forms
const NEW_USER_FORM = '232284685667167'
const EXISTING_USER_FORM = '232284685667167'

// live nurselab forms
//const NEW_USER_FORM = '231875398657172'
//const EXISTING_USER_FORM = '231875728161159'

const mapDispatchToProps = (dispatch) => {
    return {
        hideBottomNav: () => {
            dispatch(hideBottomNav())
        },
        showBottomNav: () => {
            dispatch(showBottomNav())
        },
    }
}
const mapStateToProps = (state) => ({ user: state.user })

const Onboarding2 = (props) => {
    const [url, setUrl] = useState('') // storing the uploaded file
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    const onFormSubmission = (msg) => {
        if (
            msg &&
            msg.data &&
            msg.data.action &&
            msg.data.action === 'submission-completed'
        ) {
            console.log('MOVING TO HOME')
            props.history.push('/#onboardingSuccess')
        }
        if (msg && msg.data && msg.data) {
            console.log('new msg')
            console.log(msg.data)
            console.log(JSON.stringify(msg.data))
        }
    }
    React.useEffect(() => {
        /*
        let firstRun = setInterval(function () {
            if (window.Tawk_API) {
                window.Tawk_API.onLoad = function () {
                    window.Tawk_API.hideWidget()
                    clearInterval(firstRun)
                }
            }
        }, 300)
        */
        Utils.hideTawk()

        setUrl('about:blank')
        document.body.style.overflow = 'hidden'
        props.hideBottomNav()

        window.addEventListener('message', onFormSubmission)
        return () => {
            document.body.style.overflow = 'scroll'
            window.removeEventListener('message', onFormSubmission)

            //setUrl('https://google.com')
            props.showBottomNav()
        }
    }, [])
    const userId = props.user.id

    // multiple criteria for giving existing user a new form:
    // 1. is activated - if activated user, rnventory user already previously already uploaded their documents
    // 2. initialCredentialSource === 'ezzy staffing' then they have credentials on file
    //
    // once onboarding is complete, registration agreement is signed. in user profile, user has hasNurseLabStaffingRegistrationAgreement boolean = true

    const getFormId = function () {
        let userSource = props.user.source
        // prior rnventory user so has credentials on file
        if (props.user.isActivated) {
            return EXISTING_USER_FORM
        }

        if (userSource === 'adminPanel') {
            if (props.user.initialCredentialSource === 'rnventory') {
                return EXISTING_USER_FORM
            }
            if (props.user.initialCredentialSource === 'ezzystaffing') {
                // all ezzy staffing nurses have credentials on file
                return EXISTING_USER_FORM
            }
            // user source = admin panel and new user, assume exisitng user w/ credentials on file
            if (props.user.initialCredentialSource === 'nurselab') {
                return EXISTING_USER_FORM
            }
        }
        return NEW_USER_FORM
    }

    let formId = getFormId()
    React.useEffect(() => {
        if (!props.user || !props.user.firstName) {
            setUrl('')
            return
        }

        // DO NOT ADD SESSION ID IT DOES NOT WORK AND KILLS ALL FILE UPLOADS
        //    'https://form.jotform.com/' +
        //let tmpUrl =
        //    'https://apply.nurselab.co/?formId=' +
        let baseUrl = ''
        if (!isApp) {
            // render src script on mobile devices because it does not crash on photo uploads.
            baseUrl = 'https://form.jotform.com/jsform/'
        } else {
            // need to test. does it crash on photo uploads? for APPS. should work because not iframe and loading directly from raw page
            baseUrl = 'https://form.jotform.com/jsform/'
            //baseUrl = 'https://form.jotform.com/'
        }
        let tmpUrl =
            baseUrl +
            formId +
            '?userId=' +
            userId +
            '&name[first]=' +
            props.user.firstName +
            '&name[last]=' +
            props.user.lastName +
            '&email=' +
            props.user.email +
            '&phone=' +
            props.user.phone +
            '&initialCredentialSource=' +
            props.user.initialCredentialSource +
            '&source=app'
        //setUrl(tmpUrl)
        setUrl(tmpUrl)
        console.log(url)
    }, [props.user])
    //let isSignupFinished = false
    let isApp = Capacitor.isNativePlatform()
    console.log('is this app ' + isApp)
    /*
    if (isApp) {
        var ref = InAppBrowser.create(url, '_blank', 'location=no,zoom=no')
        //var ref = InAppBrowser.create(url, '_blank', 'zoom=no')
        ref.on('loadstart').subscribe((event) => {
            if (event.url.indexOf('https://nurses.nurselab.co') > -1) {
                console.log('load start found matching nurselab url!')
                ref.close()
                ShiftService.getAvailableShifts()
                isSignupFinished = true
                // does not load correctly, revert to window.locaiton
                //props.history.push('/history')
                window.location.href = '/'
            }
        })
        ref.on('exit').subscribe((event) => {
            if (!isSignupFinished) {
                CapacitorApp.exitApp()
            }
        })
        return
    }
    */

    /*
                <iframe
                    title="signup"
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: isDesktop ? '100vh' : '100%',
                        left: 0,
                        backgroundColor: 'rgb(246, 246, 246)',
                        zIndex: isDesktop ? 1 : '10000000000000',
                    }}
                    frameBorder="0"
                    allow="camera *;"
                    scrolling="no"
                    onload="window.parent.scrollTo(0,0)"
                    allowtransparency="true"
                    src={url}
                />
                */
    if (!isApp) {
        //postscribe('#onboardingBody', '<script src=' + url + '></script>')
    }
    let html =
        '<style>iframe{width:100%;height:100% !important;position:absolute;} </style><script src=' +
        url +
        '></script>'

    // rollbar errorboundary debug - because iframe tries to do a cross origin script resize on screen. ignoring it.
    return (
        <div>
            {url ? (
                <ErrorBoundary level={LEVEL_DEBUG}>
                    <PostScribe
                        html={html}
                        style={{
                            position: 'fixed',
                            width: '100%',
                            height: isDesktop ? '100vh' : '100%',
                            left: 0,
                            backgroundColor: 'rgb(246, 246, 246)',
                            zIndex: isDesktop ? 1 : '10000000000000',
                        }}
                    />
                </ErrorBoundary>
            ) : (
                'Loading...'
            )}
        </div>
    )
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Onboarding2)
