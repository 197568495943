import React from 'react'

import AuthService from '../services/authService.js'
import Container from '@mui/material/Container'

//import { withStyles } from '@mui/material/styles'

import { setUser } from '../features/User/userSlice.js'
//import userService from '../services/userService.js'

import { compose } from 'redux' //Code
import { connect } from 'react-redux' //Code

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import Tooltip from '@mui/material/Tooltip'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import useMediaQuery from '@mui/material/useMediaQuery'
import axios from 'axios'

//import Utils from '../helpers/utils.js'

import Button from '@mui/material/Button'
import {
    openTimesheetDialog,
    toggleTimesheetUpload,
} from '../features/Timesheet/timesheetSlice'

import Timesheet from '../features/Timesheet/Timesheet'
import TzMoment from '../plugins/TzMoment'
//const moment = require('moment')

let oldServerData = []

const useStyles = makeStyles({
    table: {
        //minWidth: 700
        minWidth: '70%',
        paddingTop: '3em',
    },
})

const mapStateToProps = (state) => ({
    user: state.user,
    shiftCollections: state.shiftCollections,
})
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (userState) => {
            dispatch(setUser(userState))
        },
        openTimesheetDialog: (shiftId) => {
            console.log('opening timesheet dialog')

            let data = {
                shift: {},
                shiftRequest: {},
                shiftCollection: {},
                shiftId: shiftId,
            }
            dispatch(openTimesheetDialog(data))
        },
        uploadTimesheet: (shiftId) => {
            let data = {
                shiftId: shiftId,
            }
            dispatch(toggleTimesheetUpload(data))
        },
    }
}

/*
function roundUpHalf(num) {
    let intPart = Math.trunc(num) // returns 3
    let floatPart = Number((num - intPart).toFixed(2)) // return 0.2
    let roundedFloat = 0
    if (floatPart >= 0.5) {
        roundedFloat = 1
    } else if (floatPart > 0) {
        roundedFloat = 0.5
    }
    let total = intPart + roundedFloat
    total = total.toFixed(1)

    return total
}
*/

const Payments = (props) => {
    let timezone = 'America/Los_Angeles'
    if (props.user.timezone) {
        timezone = props.user.timestamp
    }

    // assume all shifts are in same timezone user lives
    // in future support timezones of facilities?
    let tzMoment = new TzMoment(timezone)
    /*
    let tmp1 =window.sessionStorage('pendingLedger')
    let tmp2 = window.sessionStorage('currentLedger')
    if(!tmp1){

        tmp1=[]
    }else{
        tmp1 = JSON.parse(tmp1)

    }
    if(!tmp2){

        tmp2=[]
    }else{
        tmp2 = JSON.parse(tmp2)

    }
    */

    const [pendingLedger, setPendingLedger] = React.useState([])
    const [currentLedger, setCurrentLedger] = React.useState([])
    const [pendingInvoiceTotal, setPendingInvoiceTotal] = React.useState(0)
    const [currentInvoiceTotal, setCurrentInvoiceTotal] = React.useState(0)

    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    const handleOpenViewTimesheetDialog = async (shiftId) => {
        props.openTimesheetDialog(shiftId)
    }

    const createRows = (ledgerItems) => {
        let tmpRows = []
        let bigTotal = 0

        ledgerItems.forEach((item) => {
            let role = ''
            if (item.role) {
                role = item.role.shortName + ': '
            }

            let msg = item.description
            /*
        if (hrs > 5) {
            msg += '. Includes 30 min lunch break.'
        }
        */
            let total = item.amount ? item.amount : item.pendingAmount
            total = parseFloat(total).toFixed(2)
            bigTotal = (parseFloat(bigTotal) + parseFloat(total)).toFixed(2)
            let date = ''
            if (item.shiftCollection) {
                date = item.shiftCollection.start
            } else {
                date = item.created
            }

            let hrs = ''
            if (item.shiftCollection && item.shiftCollection.duration) {
                hrs = item.shiftCollection.duration
            }

            let rate = ''
            if (item.payRate && item.payRate.payoutHourlyRate) {
                rate = item.payRate.payoutHourlyRate.toFixed(2)
            }

            if (isDesktop) {
                date = tzMoment.moment(date).format('l')
            } else {
                date = tzMoment.moment(date).format('M/D')
            }
            let id = item.id

            let timesheetButton
            //TODO BETTER FIX AFTER CALL
            if (item.shift.hasTimesheet) {
                timesheetButton = (
                    <Tooltip title="View Timesheet">
                        <Button
                            variant="contained"
                            onClick={() =>
                                handleOpenViewTimesheetDialog(item.shift_id)
                            }
                        >
                            View
                        </Button>
                    </Tooltip>
                )
            } else if (!item.shift.isCanceled) {
                timesheetButton = (
                    <Tooltip title="Upload Signed Timesheet">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => props.uploadTimesheet(item.shift_id)}
                        >
                            Upload
                        </Button>
                    </Tooltip>
                )
            }
            let tmp = { id, msg, hrs, total, rate, date, timesheetButton }
            tmpRows.push(tmp)
        })
        return [tmpRows, bigTotal]
    }

    const populateTable = (ledgerItems) => {
        let pendingItems = []
        let completedItems = []

        ledgerItems.forEach((item) => {
            let isBefore = false
            if (item.shiftCollection) {
                isBefore = tzMoment
                    .moment(new Date())
                    .isBefore(item.shiftCollection.end)
            } else {
                isBefore = tzMoment.moment(new Date()).isBefore(item.postDate)
            }

            if (isBefore) {
                pendingItems.push(item)
            } else {
                completedItems.push(item)
            }
        })

        let pendingRows = []
        let completedRows = []
        let pTotal = 0
        let cTotal = 0
        if (pendingItems.length === 0) {
            /*
                pendingRows.push({
                    id: 0,
                    msg: 'No pending balance',
                    total: 0
                })
                */
        } else {
            ;[pendingRows, pTotal] = createRows(pendingItems)
        }
        if (completedItems.length === 0) {
            /*
                completedRows.push({
                    id: 0,
                    msg: 'No balance',
                    total: 0
                })
                */
        } else {
            ;[completedRows, cTotal] = createRows(completedItems)
        }

        if (completedItems.length === 0 && pendingItems.length === 0) {
            completedRows.push({
                id: 0,
                msg: 'No shifts taken in the last 90 days',
                total: 0,
            })
        }
        setPendingLedger(pendingRows)
        setPendingInvoiceTotal(pTotal)

        setCurrentLedger(completedRows)
        setCurrentInvoiceTotal(cTotal)
    }
    const pageLoad = async () => {
        let headers = await AuthService.getHeader()

        let res
        try {
            res = await axios.get(`/api/nurse/account/payments/`, {
                headers: headers,
            })
        } catch (e) {
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }
        if (!res) {
            return
        }
        console.log(res.data)
        let ledgerItems = res.data
        oldServerData = ledgerItems
        populateTable(ledgerItems)

        // filter into historic and new bilings
    }
    React.useEffect(() => {
        window.scrollTo(0, 0)

        if (oldServerData) {
            populateTable(oldServerData)
        }
        pageLoad()
        console.log('loading billing use effect')
    }, [])
    React.useEffect(() => {
        console.log('re-loading data upload')

        pageLoad()
    }, [props.shiftCollections])
    const classes = useStyles()

    return (
        <Container
            style={{
                maxWidth: '100%',
                width: '100%',
                marginLeft: isDesktop ? '3em' : '0',
                marginRight: isDesktop ? '3em' : '0',
                marginTop: isDesktop ? '2em' : '0',
                height: !isDesktop
                    ? `calc(100vh - 3.5em - ${props.bottomSafeArea}px - ${props.topSafeArea}px )`
                    : 'auto',
                overflowY: !isDesktop ? 'scroll' : 'auto',
            }}
        >
            <h2>Activity</h2>
            {/*}<h2> Shifts for the last 90 days </h2>*/}
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="spanning table">
                    <TableHead>
                        {/*}
                        <TableRow>
                            <TableCell
                                align="center"
                                colSpan={isDesktop ? 4 : 3}
                            >
                                Activity
                            </TableCell>
                        </TableRow>
                            */}
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Description</TableCell>

                            {isDesktop ? <TableCell>Hrs</TableCell> : ''}
                            <TableCell align="">Timesheet</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pendingLedger.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.msg} (Upcoming)</TableCell>

                                {isDesktop ? (
                                    <TableCell>{row.hrs}</TableCell>
                                ) : (
                                    ''
                                )}
                                <TableCell align="">
                                    {row.timesheetButton}
                                </TableCell>
                                {/*
                                <TableCell align="right">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        component="label"
                                    >
                                        Test
                                    </Button>
                                </TableCell>
                                */}
                            </TableRow>
                        ))}
                        {currentLedger.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.msg}</TableCell>

                                {isDesktop ? (
                                    <TableCell>{row.hrs}</TableCell>
                                ) : (
                                    ''
                                )}
                                <TableCell align="">
                                    {row.timesheetButton}
                                </TableCell>
                                {/*
                                <TableCell align="right">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        component="label"
                                    >
                                        Test
                                    </Button>
                                </TableCell>
                                */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            {/* Base Rate. Time and a half is paid for shifts exceeding
            8hrs. Double time for shifts exceeding 12 hours. 30 minute lunch
            break not included in hours worked.
            */}
            <br />
            {isDesktop ? '*30 minute lunch break not included in hours.' : ''}
            <br />
            {/*
            **Hours recorded may change as timesheets are submitted and
            reviewed.
            */}
            <br />
            <br />
            <br />
            <Timesheet />
        </Container>
    )
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Payments)
