import React, { useEffect } from 'react'
import { connect } from 'react-redux' //Code

import {
    MultiChatWindow,
    useMultiChatLogic,
    MessageList,
    MultiChatSocket,
} from 'react-chat-engine-advanced'

import { ChatList } from './ChatList.tsx'
import { ChatFeed } from './ChatFeed.tsx'
import Message from './Message.js'
//import { popNavStack, pushNavStack } from '../NavStack/navStackSlice'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { popNavStack, pushNavStack } from '../NavStack/navStackSlice'
import moment from 'moment'
import {
    hideBottomNav,
    showBottomNav,
} from '../../features/BottomNav/bottomNavSlice'

const mapStateToProps = (state) => {
    return {
        navStack: state.navStack,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        hideBottomNav: () => {
            dispatch(hideBottomNav())
        },
        showBottomNav: () => {
            dispatch(showBottomNav())
        },
        popNavStack: () => {
            dispatch(popNavStack())
        },
        pushNavStack: (item) => {
            dispatch(pushNavStack(item))
        },
    }
}

/*
  const renderChats = (chats: Array<ChatObject>) => {
      return chats.map((chat, index) => {
            const otherPerson =
              chat &&
              chat.people.find((person) => person.person.username !== props.username);
            const title = !chat
              ? ''
              : chat.is_direct_chat && otherPerson
              ? otherPerson.person.username
              : chat.title;
            const timeStamp = getDateTime(chat.created, props.timezoneOffset)
              .toString()
              .substr(4, 6);
            const hasNotification = props.username
              ? !readLastMessage(props.username, chat)
              : false;

            return (
                    <ChatCard
                      key={`chat_${index}`}
                      chat={chat}
                      title={title}
                      description={getDescription(chat)}
                      timeStamp={timeStamp}
                      isActive={activeChatId === chat.id}
                      hasNotification={hasNotification}
                      onClick={() =>
                                  props.onChatCardClick && props.onChatCardClick(chat.id)
                                }
                      avatarUsername={chat.last_message.sender?.username}
                      avatarUrl={
                                  chat.last_message.sender
                                    ? chat.last_message.sender.avatar
                                    : 'https://chat-engine-assets.s3.amazonaws.com/empty-chat-thumb.png'
                                }
                      renderChatCard={props.renderChatCard}
                    />
                  );
          });
    };
    */

const InboxWidget = (props) => {
    let projectId = 'a0bacc9d-89f0-447a-952d-9ada39b7c9b8'
    let username = props.username
    let secret = props.secret
    //let username = '87f574ee-70a0-4c35-ae5d-01fc813eeb07'
    //let secret = '87f5'
    //let username = 'scott'
    //let secret = '1234'

    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    let chatProps = {}
    chatProps = useMultiChatLogic(projectId, username, secret)

    // get timezone offset
    let offset = moment().utcOffset()
    let hrOffset = offset / 60

    // custom back behavior

    React.useEffect(() => {
        const onPopChange = (e) => {
            ///alert(JSON.stringify(props.navStack))
            if (!props.navStack.isUnlocked) {
                return
            }
            let last = props.navStack.stack.slice(-1)[0]
            //alert(JSON.stringify(props.navStack.stack))
            //alert(last)
            if (last === 'chatWindow' && !chatProps.isMobileChatListOpen) {
                props.popNavStack()
                // custom behavior
                //alert('running')
                chatProps.setIsMobileChatListOpen(true)

                window.removeEventListener('popstate', onPopChange)
                return
            }
        }
        window.addEventListener('popstate', onPopChange)
    }, [props.navStack.stack])

    useEffect(() => {
        /// on load if not desktop defaul to chat list open
        if (!isDesktop) {
            chatProps.setIsMobileChatListOpen(true)
        }
    }, [isDesktop])

    const [isFirstLoaded, setIsFirstLoaded] = React.useState(false)

    useEffect(() => {
        // force chat list open on initial load for APPS. otherwise, by default, inbox will open to latest chat. ugly
        if (!isDesktop && !isFirstLoaded && !chatProps.isMobileChatListOpen) {
            chatProps.setIsMobileChatListOpen(true)
        }

        //custom back button applies only to phones
        if (!isDesktop && isFirstLoaded && !chatProps.isMobileChatListOpen) {
            props.pushNavStack('chatWindow')
        }

        if (chatProps.isMobileChatListOpen) {
            props.showBottomNav()
        } else {
            props.hideBottomNav()
        }
    }, [chatProps.isMobileChatListOpen, isFirstLoaded, isDesktop])

    //custom back button behavior

    let topOffset = props.topSafeArea + 'px'
    let bottomOffset = props.bottomSafeArea + 'px'

    let backButtonBarHeight = '4em'
    return (
        <>
            <MultiChatWindow
                {...chatProps}
                style={{
                    height: isDesktop
                        ? '100vh'
                        : chatProps.isMobileChatListOpen
                        ? //? 'calc(100vh - 88px)'
                          //? 'calc(100vh - 88px)'
                          `calc(100vh - ${topOffset} - ${bottomOffset} - ${backButtonBarHeight})`
                        : `calc(100vh - ${topOffset} - ${bottomOffset} - ${backButtonBarHeight})`,
                    marginTop: topOffset,
                }}
                chatSettingsMobileButtonStyle={{ display: 'none' }}
                renderChatForm={(creds) => {
                    return (
                        <div
                            style={{
                                fontSize: '2em',
                                fontWeight: '600',
                                padding: '0.5em',
                                paddingLeft: '0.5em',
                            }}
                        >
                            Inbox
                        </div>
                    )
                }}
                chatFeedColumnStyle={
                    isDesktop
                        ? {
                              maxWidth: '75%',
                              width: '75%',
                              flex: '0 0 75%',
                          }
                        : {
                              maxWidth: '100%',
                              width: '100%',
                              flex: '0 0 75%',
                              borderWidth: 0,
                              overflowX: 'hidden',
                          }
                }
                chatSettingsColumnStyle={{
                    maxWidth: '0',
                    width: '0',
                    flex: '0',
                }}
                renderChatSettings={(chatAppState) => {}}
                renderMessageList={(messageList) => (
                    <MessageList
                        messages={messageList.messages}
                        username={messageList.username}
                        chat={messageList.chat}
                        style={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            position: 'relative',
                            backgroundColor: 'white',
                            height: isDesktop
                                ? 'calc(100% - 85px - 54px)'
                                : 'calc(100% - 225px)',
                            marginLeft: isDesktop ? '2em' : '1em',
                            marginRight: isDesktop ? '2em' : '1em',
                        }}
                        renderMessage={(message) => {
                            return (
                                <Message
                                    firstName={props.firstName}
                                    lastName={props.lastName}
                                    username={props.username}
                                    offset={hrOffset}
                                    {...message}
                                />
                            )
                        }}
                        messageStyle={{
                            bubbleStyle: {
                                color: 'black',
                                display: 'inline-block',
                                backgroundColor: 'black',
                                cursor: 'pointer',
                                textAlign: 'right',
                                fontSize: '30em',
                            },
                        }}
                        bubbleStyle={{
                            color: 'black',
                            display: 'inline-block',
                            backgroundColor: 'black',
                            cursor: 'pointer',
                            textAlign: 'right',
                            fontSize: '30em',
                        }}
                    />
                )}
                renderChatList={(chatList) => {
                    return <ChatList {...chatList} />
                }}
                renderChatFeed={(chatFeed) => {
                    return (
                        <ChatFeed
                            {...chatFeed}
                            bottomSafeArea={props.bottomSafeArea}
                            timezoneOffset={hrOffset}
                            isMobileChatListOpen={
                                chatProps.isMobileChatListOpen
                            }
                        />
                    )
                }}
            />
            <MultiChatSocket
                {...chatProps}
                projectId={chatProps.projectId}
                username={chatProps.username}
                secret={chatProps.secret}
                //@ts-ignore
                onConnect={() => setIsFirstLoaded(true)}
                onAuthFail={chatProps.onAuthFail}
                onNewChat={chatProps.onNewChat}
                onEditChat={chatProps.onEditChat}
                onDeleteChat={chatProps.onDeleteChat}
                onNewMessage={chatProps.onNewMessage}
                onEditMessage={chatProps.onEditMessage}
                onDeleteMessage={chatProps.onDeleteMessage}
                onIsTyping={chatProps.onIsTyping}
            />
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(InboxWidget)
