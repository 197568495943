import { Auth } from 'aws-amplify'
//import store from '../app/store'

const AuthService = {
    isLoggedIn: async function () {
        let user = await Auth.currentAuthenticatedUser()
        //console.log('user ' + JSON.stringify(user))
        if (user) {
            console.log('user IS LOGGED IN ' + JSON.stringify(user))
            return true
        } else {
            console.log('not logged in ' + JSON.stringify(user))
            return false
        }
    },
    /*
    isLoggedInAndUserLoaded: async function () {
        console.log('test')
        if (await AuthService.isLoggedIn()) {
            //TODO fix hack - using profile as a proxy to see if user state has been pulled initially from server
            if (store.getState().user.profile) {
                return true
            } else {
                console.log('test 3 no logged in')
                return false
            }
        }
        return false
    },
    */
    getAccessToken: async function () {
        let auth = await Auth.currentSession()
        let accessToken = auth.getAccessToken()
        let token = accessToken.getJwtToken()
        return token
    },
    getHeader: async function () {
        let token = await this.getAccessToken()
        let headers = { Authorization: 'Bearer ' + token }
        return headers
    },
}

export default AuthService
